// import React from "react";
// import {
//   ListItem,
//   ListItemButton,
//   ListItemIcon,
//   ListItemText,
// } from "@mui/material";
// // import { colors } from "../../res/colors";
// import { useLocation, useNavigate } from "react-router-dom";

// const MenuList = ({ text, index, icon }) => {
//   const navigate = useNavigate();
//   const location = useLocation();

//   const onHandleNavigation = () => {
//     navigate(fullPath);
//   };
//   // const basePath = '/dashboard';
//   const basePath = "";

//   const fullPath = `${basePath}${text.path === "." ? "" : text.path}`;
//   const isActive = location.pathname === fullPath;
//   return (
//     <ListItem
//       sx={{
//         backgroundColor: isActive ? "white" : "transparent",
//         color: isActive ? "black" : "black",
//         ":hover": {
//           color: "white",
//           backgroundColor: "blue",
//           cursor: "pointer",
//         },
//       }}
//       key={text.id}
//       disablePadding
//     >
//       <ListItemButton onClick={onHandleNavigation}>
//         <ListItemIcon>
//           {/* {isActive ? text.activeIcon() : text.icon()} */}
//           {text.icon()}
//         </ListItemIcon>
//         <ListItemText primary={text.title} />
//       </ListItemButton>
//     </ListItem>
//   );
// };

// export default MenuList;

import React, { useState } from "react";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

const MenuList = ({ text }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const fullPath = text.path || "";
  const isActive = location.pathname === fullPath;

  const onHandleNavigation = (path) => {
    navigate(path);
  };

  const handleToggle = () => {
    if (text.submenu) {
      setOpen(!open);
    } else {
      onHandleNavigation(fullPath);
    }
  };

  return (
    <>
      <ListItem
        sx={{
          backgroundColor: isActive ? "white" : "transparent",
          color: isActive ? "black" : "black",
          ":hover": {
            color: "white",
            backgroundColor: "blue",
            cursor: "pointer",
          },
        }}
        key={text.id}
        disablePadding
      >
        <ListItemButton onClick={handleToggle}>
          <ListItemIcon>{text.icon()}</ListItemIcon>
          <ListItemText primary={text.title} />
        </ListItemButton>
      </ListItem>
      {text.submenu && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          {text.submenu.map((subitem) => (
            <ListItem
              key={subitem.id}
              sx={{
                pl: 4, // Indent submenu items
              }}
              disablePadding
            >
              <ListItemButton onClick={() => onHandleNavigation(subitem.path)}>
                <ListItemText primary={subitem.title} />
              </ListItemButton>
            </ListItem>
          ))}
        </Collapse>
      )}
    </>
  );
};

export default MenuList;
