import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changepassword,
  userdetails,
} from "../../stores/dashboardStores/dashboardSlice";
import Profile from "../../components/Profile";
// import Profile from "../../components/Profile";

const ProfilePage = () => {
  const dispatch = useDispatch();

  const userDetailsData = useSelector(
    (state) => state.dashboardSlice.userDetailData
  );

  const onHandleSubmit = async (val) => {
    console.log(val, "VAlue inside page");
    // const ticketData = [val];
    return dispatch(changepassword(val));
  };

  useEffect(() => {
    dispatch(userdetails());
  }, [dispatch]);

  return (
    <div>
      <Profile
        userDetailsData={userDetailsData}
        onHandleSubmit={onHandleSubmit}
      />
    </div>
  );
};

export default ProfilePage;
