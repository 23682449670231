import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  assignTicket,
  openTicket,
} from "../../stores/ticketmanagementStores/ticketmanagementSlice";
import ApprovedTickets from "../../components/ticketmanagement/ApprovedTickets";

const ApproveticketsPage = () => {
  const dispatch = useDispatch();

  const ApproveTciketData = useSelector(
    (state) => state.ticketmanagementSlice.openTicketData
  );

  const data = "ASSIGNED";

  const onHandleSubmit = async (val) => {
    console.log(val, "value inside approve page");
    dispatch(assignTicket(val)).then(() => {
      dispatch(openTicket(data));
    });
  };

  useEffect(() => {
    dispatch(openTicket(data));
  }, [dispatch]);

  return (
    <div>
      <ApprovedTickets
        openTicketData={ApproveTciketData}
        onHandleSubmit={onHandleSubmit}
      />
    </div>
  );
};

export default ApproveticketsPage;
