import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { assignticketApi, openticketsApi } from "./ticketmanagementApi";

export const openTicket = createAsyncThunk("openTicket", async (payload) => {
  const response = await openticketsApi(payload);
  console.log(response, "response of open tickets in slice");
  return response;
});

export const assignTicket = createAsyncThunk(
  "assignTicket",
  async (payload) => {
    console.log(payload, "Payload inside slice");
    const response = await assignticketApi(payload);
    console.log(response, "response of assign tickets in slice");
    return response;
  }
);

const initialState = {
  openTicketData: [],
  openTicketLoading: false,
  assignTicketData: [],
  assignTicketLoading: false,
};

export const ticketmanagementSlice = createSlice({
  name: "ticket_management_Slice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(openTicket.pending, (state) => {
        state.openTicketLoading = true;
      })
      .addCase(openTicket.fulfilled, (state, action) => {
        state.openTicketLoading = false;
        state.openTicketData = action.payload;
      })
      .addCase(openTicket.rejected, (state) => {
        state.openTicketLoading = false;
      })
      .addCase(assignTicket.pending, (state) => {
        state.assignTicketLoading = true;
      })
      .addCase(assignTicket.fulfilled, (state, action) => {
        state.assignTicketLoading = false;
        state.assignTicketData = action.payload;
      })
      .addCase(assignTicket.rejected, (state) => {
        state.assignTicketLoading = false;
      });
  },
});

export default ticketmanagementSlice.reducer;
