import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  styled,
  Typography,
  Box,
  TableContainer,
  TablePagination,
  InputBase,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Stack,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { LoadingButton } from "@mui/lab";
import { Check, Dangerous, FindInPage } from "@mui/icons-material";
import { toast } from "react-toastify";

// Styled components for the table head and body rows
const StyledTableHead = styled(TableHead)({
  backgroundColor: "#027BFE",
});

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#EFF6FF",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#BFDBFE",
  },
}));

const ApprovedTickets = ({ openTicketData, onHandleSubmit }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [showSearchBar, setShowSearchBar] = useState(false); // State to toggle search bar
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openDoc, setOpenDoc] = useState(false);
  const handleOpenDoc = () => setOpenDoc(true);
  const handleCloseDoc = () => {
    setOpenDoc(false);
    setSelectedPath(null);
  };
  const [selectedPath, setSelectedPath] = useState(null);
  const [reason, setReason] = useState("");

  const handleSearchToggle = () => {
    setShowSearchBar(!showSearchBar);
    setSearchTerm(""); // Clear search term when toggling search bar
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const [teams, setTeams] = useState({
    id: "",
    status: "",
    reason: "",
  }); // State for selected teams

  const handleChangeStatus = (updatedTeam) => {
    console.log(updatedTeam, "recievde in handlecstatus");

    onHandleSubmit(updatedTeam);
    return updatedTeam;
  };

  // Highlight the matching search term in the text
  const highlightText = (text, highlight) => {
    if (!text) return ""; // Fallback for null or undefined values
    if (!highlight) return text;
    const parts = text.split(new RegExp(`(${highlight})`, "gi"));
    return parts.map((part, index) =>
      part.toLowerCase() === highlight.toLowerCase() ? (
        <span key={index} style={{ backgroundColor: "yellow" }}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  // Filter data based on search term across multiple fields
  const filteredData = openTicketData.filter((row) => {
    const formattedDate = new Date(row.date).toLocaleString();
    const formattedCompletionDate = new Date(
      row.date_completion
    ).toLocaleDateString();
    return [
      "id",
      "remark",
      "issueType",
      "subject",
      "status",
      "parentName",
      "team",
      "userid",
      formattedDate,
      formattedCompletionDate,
    ].some((key) =>
      String(row[key] || row.userid)
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
  });

  const onApprove = (ticketId) => {
    setTeams((prev) => {
      const updatedTeams = { ...prev, id: `${ticketId}`, status: "APPROVED" };
      console.log(updatedTeams, "this will go in request");
      handleChangeStatus(updatedTeams);
    });
  };
  const onReject = (ticketId) => {
    handleOpen();
    setTeams((prev) => {
      const updatedTeams = { ...prev, id: `${ticketId}`, status: "REJECTED" };
      setTeams(updatedTeams);
    });
  };

  const handleConfirm = () => {
    onConfirm(reason);
  };

  const onConfirm = (text) => {
    if (text.length === 0) {
      toast.error("Please Enter Reason");
      return;
    }
    setTeams((prev) => {
      console.log(prev);
      const updatedTeams = { ...prev, reason: `${text}` };
      console.log(prev);
      setTeams(updatedTeams);
      onHandleSubmit(updatedTeams);
    });
    handleClose();
  };
  // Paginate filtered data
  const paginatedData = filteredData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleViewDoc = (doc) => {
    const url = `https://testapisupport.ipaisa.co.in/supportapp/auth/ticket/files/${doc}`;
    setSelectedPath(url);
    handleOpenDoc();
  };

  useEffect(() => {}, [open, openDoc]);

  return (
    <div>
      {/* Modal for Reason */}
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{ position: "absolute", left: "20%" }}
      >
        <DialogTitle>Ticket Reject</DialogTitle>
        <DialogContent>
          <Typography>Enter Reason For Rejecting Ticket</Typography>
          <TextField
            variant="outlined"
            label="Reason"
            sx={{ width: "100%" }}
            required
            multiline
            value={reason} // Bind the value to state
            onChange={(e) => setReason(e.target.value)} // Update the state on change
          ></TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" onClick={handleConfirm}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      {/* Modal for Document */}
      {/* <Dialog
        open={openDoc}
        onClose={handleCloseDoc}
        fullWidth
        sx={{ position: "absolute", left: "20%", width: "75%", height: "75%" }}
      >
        <DialogTitle>Ticket Document</DialogTitle>
        <DialogContent>
          <img
            src={selectedPath}
            alt="Receipt Preview"
            style={{
              cursor: "zoom-in",
              transition: "transform 0.3s, transform-origin 0.3s",
            }}
            onClick={(e) => {
              const img = e.target;
              if (img.style.transform) {
                // Reset zoom
                img.style.transform = "";
                img.style.transformOrigin = "center center";
                img.style.cursor = "zoom-in";
              } else {
                // Get mouse position relative to the image
                const rect = img.getBoundingClientRect();
                const offsetX = e.clientX - rect.left; // Mouse X relative to the image
                const offsetY = e.clientY - rect.top; // Mouse Y relative to the image

                // Calculate transform origin as a percentage
                const originX = (offsetX / rect.width) * 100;
                const originY = (offsetY / rect.height) * 100;

                // Apply zoom with transform origin
                img.style.transform = "scale(2)";
                img.style.transformOrigin = `${originX}% ${originY}%`;
                img.style.cursor = "zoom-out";
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleCloseDoc}>
            Done
          </Button>
        </DialogActions>
      </Dialog> */}
      <Dialog
        open={openDoc}
        onClose={handleCloseDoc}
        fullWidth
        maxWidth="lg" // Use "lg" or customize via styles
        style={{
          left: "20%",
          width: "75%",
          maxWidth: "75%",
        }} // Custom width
      >
        <DialogTitle>Receipt Preview</DialogTitle>
        <DialogContent style={{ textAlign: "center", overflow: "hidden" }}>
          {selectedPath ? (
            <div
              style={{
                display: "inline-block",
                position: "relative",
                overflow: "hidden",
                width: "100%",
              }}
            >
              <img
                src={selectedPath}
                alt="Receipt Preview"
                style={{
                  maxWidth: "100%",
                  transition: "transform 0.3s, transform-origin 0.3s",
                  cursor: "zoom-in",
                }}
                onClick={(e) => {
                  const img = e.target;
                  if (img.style.transform) {
                    // Reset zoom
                    img.style.transform = "";
                    img.style.transformOrigin = "center center";
                    img.style.cursor = "zoom-in";
                  } else {
                    // Get mouse position relative to the image
                    const rect = img.getBoundingClientRect();
                    const offsetX = e.clientX - rect.left; // Mouse X relative to the image
                    const offsetY = e.clientY - rect.top; // Mouse Y relative to the image

                    // Calculate transform origin as a percentage
                    const originX = (offsetX / rect.width) * 100;
                    const originY = (offsetY / rect.height) * 100;

                    // Apply zoom with transform origin
                    img.style.transform = "scale(2)";
                    img.style.transformOrigin = `${originX}% ${originY}%`;
                    img.style.cursor = "zoom-out";
                  }
                }}
              />
            </div>
          ) : (
            <p>No receipt available.</p>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleCloseDoc}>
            Done
          </Button>
        </DialogActions>
      </Dialog>

      <Typography
        variant="h6"
        sx={{ mb: 1, color: "grey", marginBottom: "2%" }}
      >
        Ticket Management > Approved Tickets
      </Typography>
      <Paper
        elevation={3}
        sx={{
          overflow: "auto",
          border: "2px solid #027BFE", // Blue border
          borderRadius: "8px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: 16,
          }}
        >
          <Typography sx={{ fontWeight: "bold", fontSize: "20px" }}>
            Waiting for Approval Tickets Table
          </Typography>
          {showSearchBar ? (
            <InputBase
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearchChange}
              sx={{
                border: "1px solid grey",
                borderRadius: "4px",
                paddingLeft: "8px",
                width: "300px",
              }}
              endAdornment={
                <IconButton onClick={handleSearchToggle}>
                  <HighlightOffIcon />
                </IconButton>
              }
            />
          ) : (
            <IconButton onClick={handleSearchToggle}>
              <SearchIcon />
            </IconButton>
          )}
        </div>

        <Box sx={{ p: 2 }}>
          <TableContainer sx={{ borderRadius: "20px 20px 0px 0px" }}>
            <Table>
              <StyledTableHead>
                <TableRow>
                  <TableCell sx={{ color: "white" }}>ID</TableCell>
                  <TableCell sx={{ color: "white" }}>Remark</TableCell>
                  <TableCell sx={{ color: "white" }}>Issue Type</TableCell>
                  <TableCell sx={{ color: "white" }}>Subject</TableCell>
                  <TableCell sx={{ color: "white" }}>Status</TableCell>
                  <TableCell sx={{ color: "white" }}>Date</TableCell>
                  <TableCell sx={{ color: "white" }}>User ID</TableCell>
                  <TableCell sx={{ color: "white" }}>Team</TableCell>
                  <TableCell sx={{ color: "white" }}>View Document</TableCell>

                  <TableCell sx={{ color: "white" }} align="center">
                    Action
                  </TableCell>
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {paginatedData.map((row) => (
                  <StyledTableRow key={row.id}>
                    <TableCell>
                      {highlightText(String(row.id), searchTerm)}
                    </TableCell>
                    <TableCell>
                      {highlightText(row.remark, searchTerm)}
                    </TableCell>
                    <TableCell>
                      {highlightText(row.issueType, searchTerm)}
                    </TableCell>
                    <TableCell>
                      {highlightText(row.subject, searchTerm)}
                    </TableCell>
                    <TableCell>
                      {highlightText(row.status, searchTerm)}
                    </TableCell>
                    <TableCell>
                      {highlightText(
                        row.date ? new Date(row.date).toLocaleString() : "N/A"
                      )}
                    </TableCell>
                    <TableCell>
                      {highlightText(row.userid, searchTerm)}
                    </TableCell>

                    <TableCell>{highlightText(row.team, searchTerm)}</TableCell>
                    <TableCell align="center">
                      {row.receiptPath ? (
                        <IconButton
                          onClick={() => {
                            handleViewDoc(row.receiptPath);
                          }}
                        >
                          <FindInPage />
                        </IconButton>
                      ) : (
                        "N/A"
                      )}
                    </TableCell>

                    <TableCell align="left">
                      <Stack direction="row">
                        <LoadingButton
                          onClick={() => onApprove(row.id)}
                          variant="contained"
                          sx={{ backgroundColor: "green" }}
                          endIcon={<Check />}
                        >
                          Approve
                        </LoadingButton>
                        <LoadingButton
                          onClick={() => onReject(row.id)}
                          variant="contained"
                          sx={{ backgroundColor: "red", marginLeft: "8px" }}
                          endIcon={<Dangerous />}
                        >
                          Reject
                        </LoadingButton>
                      </Stack>
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Pagination Controls */}
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(event, newPage) => setPage(newPage)}
            onRowsPerPageChange={(event) => {
              setRowsPerPage(parseInt(event.target.value, 10));
              setPage(0); // Reset to first page when rows per page changes
            }}
          />
        </Box>
      </Paper>
    </div>
  );
};

export default ApprovedTickets;
