import * as React from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  Checkbox,
  Typography,
  IconButton,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import FilterListIcon from "@mui/icons-material/FilterList";

function createTicketData(id, lastUpdate, assignTo, category, priority, status) {
  return { id, lastUpdate, assignTo, category, priority, status };
}

const tickets = [
  createTicketData(2135, "May 25, 2024", "Pratik Jadhav", "Finance", "High", "Open"),
  createTicketData(2136, "May 25, 2024", "Pratik Jadhav", "Finance", "Low", "Open"),
  createTicketData(2137, "May 25, 2024", "Rudransh Nigam", "Finance", "High", "Open"),
  createTicketData(2138, "May 25, 2024", "Pratik Jadhav", "HR", "Low", "Open"),
  createTicketData(2139, "May 25, 2024", "Pratik Jadhav", "HR", "Low", "Open"),
];

export default function TicketAssignTable() {
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [category, setCategory] = React.useState("");
  const [user, setUser] = React.useState("");

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = tickets.map((n) => n.id);
      setSelected(newSelected);
    } else {
      setSelected([]);
    }
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else {
      newSelected = newSelected.filter((item) => item !== id);
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handleUserChange = (event) => {
    setUser(event.target.value);
  };

  const filteredTickets = tickets.filter((ticket) => {
    const matchCategory = category ? ticket.category === category : true;
    const matchUser = user ? ticket.assignTo === user : true;
    return matchCategory && matchUser;
  });

  return (
    <div className="container">
      <Typography variant="h6" sx={{ mb: 1, color: "grey" }}>
        Ticket Assign
      </Typography>
      <Typography variant="h4" sx={{ mb: 2 }}>
        Ticket Assign Unassign
      </Typography>
      <Box sx={{ width: "100%", p: 2 }}>
        <Paper sx={{ p: 3 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant="h6">Ticket List</Typography>
            </Grid>
            <Grid item xs={12} md={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Tooltip title="Filter list">
                <IconButton>
                  <FilterListIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>

          {/* Filters Section */}
          <Grid container spacing={2} alignItems="center" sx={{ mt: 2 }}>
            <Grid item xs={12} md={6}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Select Category</InputLabel>
                <Select value={category} onChange={handleCategoryChange} label="Select Category">
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="ACCOUNTS">ACCOUNTS</MenuItem>
                  <MenuItem value="SERVICES">SERVICES</MenuItem>
                  <MenuItem value="OTHERS">OTHERS</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Select User</InputLabel>
                <Select value={user} onChange={handleUserChange} label="Select User">
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="Pratik Jadhav">Pratik Jadhav</MenuItem>
                  <MenuItem value="John Doe">John Doe</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {/* Table Section */}
          <Paper sx={{ width: "100%", mt: 3, mb: 2 }}>
            <TableContainer>
              <Table aria-labelledby="tableTitle">
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#027BFE" }}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "white",
                          },
                        }}
                        indeterminate={
                          selected.length > 0 && selected.length < filteredTickets.length
                        }
                        checked={
                          filteredTickets.length > 0 && selected.length === filteredTickets.length
                        }
                        onChange={handleSelectAllClick}
                        inputProps={{ "aria-label": "select all tickets" }}
                      />
                    </TableCell>
                    <TableCell sx={{ color: "white" }}>Ticket ID</TableCell>
                    <TableCell sx={{ color: "white" }}>Last Update</TableCell>
                    <TableCell sx={{ color: "white" }}>Assign To</TableCell>
                    <TableCell sx={{ color: "white" }}>Select Category</TableCell>
                    <TableCell sx={{ color: "white" }}>Priority</TableCell>
                    <TableCell sx={{ color: "white" }}>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredTickets
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${row.id}`;

                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row.id)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                          sx={{
                            backgroundColor: index % 2 === 0 ? "#EFF6FF" : "#BFDBFE",
                          }}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </TableCell>
                          <TableCell>{`#${row.id}`}</TableCell>
                          <TableCell>{row.lastUpdate}</TableCell>
                          <TableCell>{row.assignTo}</TableCell>
                          <TableCell>{row.category}</TableCell>
                          <TableCell>
                            {row.priority === "High" ? "🔴 High" : "🟢 Low"}
                          </TableCell>
                          <TableCell>{row.status}</TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filteredTickets.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>

          {/* Button */}
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button variant="contained" color="primary">
              Assign
            </Button>
          </Box>
        </Paper>
      </Box>
    </div>
  );
}
