import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  changePasswordApi,
  summaryCardsApi,
  summaryStatsApi,
  tableStatsApi,
  ticketStatsApi,
  ticketWaitingStatsApi,
  userdeatilApi,
} from "./dashboardApi";

export const summaryStats = createAsyncThunk(
  "summaryStats",
  async (payload) => {
    const response = await summaryStatsApi(payload);
    console.log(response, "response");
    return response;
  }
);

export const ticketStats = createAsyncThunk("ticketStats", async (payload) => {
  const response = await ticketStatsApi(payload);
  console.log(response, "response of ticket stats in slice");
  return response;
});

export const tableStats = createAsyncThunk("tableStats", async (payload) => {
  const response = await tableStatsApi(payload);
  console.log(response, "response of ticket stats in slice");
  return response;
});

export const ticketWaitingStats = createAsyncThunk(
  "ticketwaitingStats",
  async (payload) => {
    const response = await ticketWaitingStatsApi();
    console.log(response, "response of ticket waiting stats in slice");
    return response;
  }
);

export const summaryCardsStats = createAsyncThunk(
  "summarycardsStats",
  async (payload) => {
    const response = await summaryCardsApi();
    console.log(response, "response of summaray cards stats in slice");
    return response;
  }
);

export const userdetails = createAsyncThunk("UserDetails", async (payload) => {
  const response = await userdeatilApi();
  console.log(response, "response of userdeatils in Slice");
  return response;
});

export const changepassword = createAsyncThunk(
  "ChangePassword",
  async (payload) => {
    const response = await changePasswordApi(payload);
    console.log(response, "response of changepassword in slice");
    return response;
  }
);

const initialState = {
  summaryStatsData: [],
  summaryStatsLoading: false,
  ticketStatsData: [],
  ticketStatsLoading: false,
  tableStatsData: [],
  tableStatsLoading: false,
  ticketWaitingStatsData: [],
  ticketWaitingStatsLoading: false,
  summaryCardStatsData: [],
  summaryCardsStatsLoading: false,
  userDetailData: [],
  userDetailLoading: false,
  changePasswordData: [],
  changePasswordLoading: false,
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(summaryStats.pending, (state) => {
        state.summaryStatsLoading = true;
      })
      .addCase(summaryStats.fulfilled, (state, action) => {
        state.summaryStatsLoading = false;
        state.summaryStatsData = action.payload;
      })
      .addCase(summaryStats.rejected, (state) => {
        state.summaryStatsLoading = false;
      })
      .addCase(ticketStats.pending, (state) => {
        state.ticketStatsLoading = true;
      })
      .addCase(ticketStats.fulfilled, (state, action) => {
        state.ticketStatsLoading = false;
        state.ticketStatsData = action.payload;
      })
      .addCase(ticketStats.rejected, (state) => {
        state.ticketStatsLoading = false;
      })
      .addCase(tableStats.pending, (state) => {
        state.tableStatsLoading = true;
      })
      .addCase(tableStats.fulfilled, (state, action) => {
        state.tableStatsLoading = false;
        state.tableStatsData = action.payload; // Check if payload is correctly passed here
      })
      .addCase(tableStats.rejected, (state) => {
        state.tableStatsLoading = false;
      })
      .addCase(ticketWaitingStats.pending, (state) => {
        state.ticketWaitingStatsLoading = true;
      })
      .addCase(ticketWaitingStats.fulfilled, (state, action) => {
        state.ticketWaitingStatsLoading = false;
        // console.log("Fetched Ticket Stats:", action.payload);
        state.ticketWaitingStatsData = action.payload;
      })
      .addCase(ticketWaitingStats.rejected, (state) => {
        state.ticketWaitingStatsLoading = false;
      })
      .addCase(summaryCardsStats.pending, (state) => {
        state.summaryCardsStatsLoading = true;
      })
      .addCase(summaryCardsStats.fulfilled, (state, action) => {
        state.summaryCardsStatsLoading = false;
        console.log("fetched summarystats:", action.payload);
        state.summaryCardStatsData = action.payload;
      })
      .addCase(summaryCardsStats.rejected, (state) => {
        state.summaryCardsStatsLoading = false;
      })
      .addCase(userdetails.pending, (state) => {
        state.userDetailLoading = true;
      })
      .addCase(userdetails.fulfilled, (state, action) => {
        state.userDetailLoading = false;
        console.log("fetched usedetails:", action.payload);
        state.userDetailData = action.payload;
      })
      .addCase(userdetails.rejected, (state) => {
        state.userDetailLoading = false;
      })
      .addCase(changepassword.pending, (state) => {
        state.changePasswordLoading = true;
      })
      .addCase(changepassword.fulfilled, (state, action) => {
        state.changePasswordLoading = false;
        console.log("fetched change password details", action.payload);
        state.changePasswordData = action.payload;
      })
      .addCase(changepassword.rejected, (state) => {
        state.changePasswordLoading = false;
      });
  },
});

// export const { setWaitingTickets } = dashboardSlice.actions;
export default dashboardSlice.reducer;
