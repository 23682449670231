import React, { useEffect } from "react";
import Dashboard from "../components/dashboard/Dashboard";
import { useDispatch, useSelector } from "react-redux";
import {
  summaryStats,
  tableStats,
  ticketStats,
} from "../stores/dashboardStores/dashboardSlice";
// import TicketWaitinglast24hours from "../components/dashboard/Tickettable";

const DashboardPage = () => {
  const dispatch = useDispatch();
  const summaryStatsData = useSelector(
    (state) => state.dashboardSlice.summaryStatsData
  );

  const ticketStatsData = useSelector(
    (state) => state.dashboardSlice.ticketStatsData
  );

  const tableStatsData = useSelector(
    (state) => state.dashboardSlice.tableStatsData
  );

  // const summaryCardsData = useSelector(
  //   (state) => state.dashboardSlice.summaryCardsStatsData
  // );

  // const openTicketsData = useSelector(
  //   (state) => state.dashboardSlice.openTicketsData
  // );

  // const summaryCardsStatsData = useSelector(
  //   (state) => state.dashboardSlice.summaryCardsStatsData
  // );

  // const waitingTickets = ticketStatsData["Waiting Tickets"] || []; // Correctly getting waiting tickets

  // const waitingTicketsData = useSelector(
  //   (state) => state.dashboardSlice.waitingTicketsData
  // );

  useEffect(() => {
    dispatch(summaryStats());
    dispatch(ticketStats());
    dispatch(tableStats());

    // dispatch(summaryCardsStats());

    // dispatch(openTickets());

    // dispatch(summaryCardsStats());

    // dispatch(ticketWaitingStats());
    // dispatch(ticketWaitingStats());
  }, [dispatch]);

  // console.log(waitingTicketsData, "He;;p");

  return (
    <div>
      <Dashboard
        summaryStatsData={summaryStatsData}
        ticketStatsData={ticketStatsData}
        tableStatsData={tableStatsData}

        // summaryCardsData={summaryCardsData}
        // summaryCardsStatsData={summaryCardsStatsData}
        // waitingTickets={waitingTicketsData} // Passing the correct data
      />
      {/* <TicketWaitinglast24hours rowData={waitingTickets} /> */}
    </div>
  );
};
export default DashboardPage;
