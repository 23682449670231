import { Card, CardContent, Typography } from "@mui/material";
import React from "react";

const CardItem = ({ title, value, color, children, onClick }) => {
  return (
    <Card
      sx={{
        backgroundColor: color || "#fff",
        color: color ? "#fff" : "#000",
        cursor: onClick ? "pointer" : "default",
      }}
      onClick={onClick}
    >
      <CardContent>
        {value !== undefined && (
          <Typography variant="h5" component="div" sx={{ textAlign: "center" }}>
            {value}
          </Typography>
        )}
        <Typography variant="body2" sx={{ textAlign: "center" }}>
          {title}
        </Typography>
        {children}
      </CardContent>
    </Card>
  );
};

export default CardItem;

// import { Card, CardContent, Typography } from "@mui/material";
// import React from "react";

// const CardItem = ({ title, value, color, children, onClick }) => {
//   return (
//     <Card
//       sx={{
//         backgroundColor: color || "#fff",
//         borderRadius: "12px",
//         padding: "16px",
//         textAlign: "center",
//         transition: "transform 0.2s",
//         color: color ? "#fff" : "#000",
//         cursor: onClick ? "pointer" : "default",
//       }}
//       onClick={onClick}
//     >
//       <CardContent>
//         {value !== undefined && (
//           <Typography variant="h5" component="div">
//             {value}
//           </Typography>
//         )}
//         <Typography variant="body2">{title}</Typography>
//         <Typography variant="caption">Last 24 Hours</Typography>
//         {children}
//       </CardContent>
//     </Card>
//   );
// };

// export default CardItem;

// import { Card, CardContent, Typography, Box } from "@mui/material";
// import React from "react";

// const CardItem = ({ title, value, color, onClick }) => {
//   return (
//     <Card
//       sx={{
//         backgroundColor: color || "#fff",
//         borderRadius: "12px",
//         padding: "16px",
//         textAlign: "center",
//         transition: "transform 0.2s",
//         color: color ? "#fff" : "#000",
//         cursor: onClick ? "pointer" : "default",
//         boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
//         "&:hover": {
//           transform: onClick ? "scale(1.05)" : "none",
//         },
//       }}
//       onClick={onClick}
//     >
//       <CardContent>
//         {/* Value Centered */}
//         {value !== undefined && (
//           <Box
//             sx={{
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               height: "50px", // Adjust height if needed
//               marginBottom: 1,
//             }}
//           >
//             <Typography
//               variant="h4"
//               component="div"
//               sx={{ fontWeight: "bold" }}
//             >
//               {value}
//             </Typography>
//           </Box>
//         )}

//         {/* Title and Last 24 Hours at Bottom */}
//         <Box
//           sx={{
//             display: "flex",
//             justifyContent: "space-between",
//             alignItems: "center",
//             marginTop: 1,
//           }}
//         >
//           <Typography variant="body2">{title}</Typography>
//           <Typography variant="caption">Last 24 Hours</Typography>
//         </Box>
//       </CardContent>
//     </Card>
//   );
// };

// export default CardItem;
