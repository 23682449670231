import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openTicket } from "../../stores/ticketmanagementStores/ticketmanagementSlice";
import ClosedTickets from "../../components/ticketmanagement/ClosedTickets";

const ClosedticketsPage = () => {
  const dispatch = useDispatch();

  const closedTickeData = useSelector(
    (state) => state.ticketmanagementSlice.openTicketData
  );

  console.log(closedTickeData, "Closed Ticket data");

  const data = "REJECTED";

  useEffect(() => {
    dispatch(openTicket(data));
  }, [dispatch]);

  return (
    <div>
      <ClosedTickets openTicketData={closedTickeData} />
    </div>
  );
};

export default ClosedticketsPage;
