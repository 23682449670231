import * as React from "react";
import {
  Box,
  Table,
  Grid2,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  Typography,
  // IconButton,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
// import FilterListIcon from "@mui/icons-material/FilterList";
import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function TicketAssign({ onHandleSubmit, openTicketData }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [category, setCategory] = useState("");
  const [user, setUser] = useState("");
  // const [teams, setTeams] = useState({}); // State for selected teams

  const [teams, setTeams] = useState({
    id: "",
    status: "ASSIGNED",
    team: "",
  }); // State for selected teams

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  // const handleUserChange = (event) => {
  //   setUser(event.target.value);
  // };

  const handleTeamChange = (ticketId, event) => {
    const selectedTeam = event.target.value;
    setTeams((prevState) => ({
      ...prevState,
      [ticketId]: {
        ...(prevState[ticketId] || {}), // Preserve existing data for this ticket ID if it exists
        id: ticketId,
        team: selectedTeam, // Update the team
        status: "ASSIGNED", // Ensure the status is updated or remains consistent
      },
    }));
  };

  // const handleTeamChange = (ticketId, event) => {
  //   const teamValue = event.target.value;
  //   setTeams((prevTeams) => ({
  //     ...prevTeams,
  //     [ticketId]: teamValue, // Store selected team for the specific ticket
  //   }));
  // };

  const handleAssign = (ticketid) => {
    // Check if a team is selected
    const ticketData = teams[ticketid];
    console.log(ticketData, "handle assign tickedata");
    if (!ticketData.team) {
      toast.error("Please select a team before assigning!", {
        position: "top-right",
        autoClose: 2000,
      });
    } else {
      onHandleSubmit(ticketData); // Assign the ticket
      toast.success("Ticket assigned successfully!", {
        position: "top-right",
        autoClose: 2000,
      });
    }
  };

  const filteredTickets = openTicketData.filter((ticket) => {
    const matchCategory = category ? ticket.issueType === category : true;
    const matchUser = user ? ticket.user.userid === user : true;
    return matchCategory && matchUser;
  });

  return (
    <div>
      <Typography
        variant="h6"
        sx={{ mb: 1, color: "grey", marginBottom: "2%" }}
      >
        Ticket Management > Ticket Assign
      </Typography>
      <div className="container">
        <Typography variant="h4" sx={{ mb: 2 }}>
          Ticket Assign
        </Typography>
        <Box sx={{ width: "100%", p: 2 }}>
          <Paper sx={{ p: 3 }}>
            <Grid2 container spacing={2} alignItems="center">
              <Grid2 item xs={12} md={6}>
                <Typography variant="h6">Ticket List</Typography>
              </Grid2>
              <Grid2
                item
                xs={12}
                md={6}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Tooltip title="Filter list">
                  {/* <IconButton>
                  <FilterListIcon />
                </IconButton> */}
                </Tooltip>
              </Grid2>
            </Grid2>

            {/* Filters Section */}
            <Grid2 container spacing={2} alignItems="center" sx={{ mt: 2 }}>
              <Grid2 item xs={12} md={6}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  sx={{ minWidth: 700 }}
                >
                  <InputLabel>Select Category</InputLabel>
                  <Select
                    value={category}
                    onChange={handleCategoryChange}
                    label="Select Category"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="Offline Bank Funds">
                      Offline Bank Funds
                    </MenuItem>
                    <MenuItem value="Info Issue">Info Issue</MenuItem>
                    <MenuItem value="Payment Issue">Payment Issue</MenuItem>
                    <MenuItem value="Other Issue">Other Issue</MenuItem>
                    <MenuItem value="Service Issue">Service Issue</MenuItem>
                  </Select>
                </FormControl>
              </Grid2>

              {/* <Grid2 item xs={12} md={6}>
              <FormControl variant="outlined" fullWidth sx={{ minWidth: 700 }}>
                <InputLabel>Select User</InputLabel>
                <Select
                  value={user}
                  onChange={handleUserChange}
                  label="Select User"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="Pratik Jadhav">Pratik Jadhav</MenuItem>
                  <MenuItem value="John Doe">John Doe</MenuItem>
                </Select>
              </FormControl>
            </Grid2> */}
            </Grid2>

            {/* Table Section */}
            <Paper sx={{ width: "100%", mt: 3, mb: 2 }}>
              <TableContainer>
                <Table aria-labelledby="tableTitle">
                  <TableHead>
                    <TableRow sx={{ backgroundColor: "#027BFE" }}>
                      <TableCell sx={{ color: "white" }}>Ticket ID</TableCell>
                      <TableCell sx={{ color: "white" }}>Remark</TableCell>
                      <TableCell sx={{ color: "white" }}>Issue Type</TableCell>
                      <TableCell sx={{ color: "white" }}>Subject</TableCell>
                      <TableCell sx={{ color: "white" }}>User</TableCell>
                      <TableCell sx={{ color: "white" }}>Status</TableCell>
                      <TableCell sx={{ color: "white" }}>Date</TableCell>
                      <TableCell sx={{ color: "white" }}>Select Team</TableCell>
                      <TableCell sx={{ color: "white" }}>Assign</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredTickets
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((ticket) => (
                        <TableRow hover tabIndex={-1} key={ticket.id}>
                          <TableCell>{ticket.id}</TableCell>
                          <TableCell>{ticket.remark}</TableCell>
                          <TableCell>{ticket.issueType}</TableCell>
                          <TableCell>{ticket.subject}</TableCell>
                          <TableCell>{ticket.userid}</TableCell>
                          <TableCell>{ticket.status}</TableCell>
                          <TableCell>
                            {ticket.date
                              ? new Date(ticket.date).toLocaleString()
                              : "N/A"}
                          </TableCell>
                          <TableCell>
                            <FormControl fullWidth sx={{ minWidth: 150 }}>
                              <InputLabel>Select Team</InputLabel>
                              <Select
                                value={teams[ticket.id]?.team || ""} // Default to an empty string if not set
                                onChange={(event) =>
                                  handleTeamChange(ticket.id, event)
                                }
                                label="Select Team"
                              >
                                <MenuItem value="">
                                  <em>None</em>
                                </MenuItem>
                                <MenuItem value="ACCOUNTS">ACCOUNTS</MenuItem>
                                <MenuItem value="SERVICES">SERVICES</MenuItem>
                                <MenuItem value="OTHERS">OTHERS</MenuItem>
                              </Select>
                            </FormControl>
                          </TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => handleAssign(ticket.id)}
                            >
                              Assign
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredTickets.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Paper>
        </Box>
        <ToastContainer />
      </div>
    </div>
  );
}
