export const colors = {

    primaryColor: '#6B28D9',
    inputTextBorderColor: '#808080',
    inputTextBorderColorRGBA: 'rgba(128,128,128,0.3)',
    dashboardBackgroundColor: '#F4F5FA',
    buttonBackgroundColor: "#0057FE",
    activeTableCOlor: '#00C308',
    profilePhotoColor:'#D9D9D9',
    cancelBackgroundColor:'rgba(217,217,217,0.3)',
    exportTextColor: '#414141',
    //generic colors
    whiteColor: 'white',
    // labelColor:'#414141',
    labelColor:'rgba(65,65,65,0.7)',
    blackColor:'#000000',
};