import React, { useState } from "react";
import {
  Box,
  Typography,
  Paper,
  InputBase,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import { LoadingButton } from "@mui/lab";

const TeamList = ({ emplistData = [], resetPassword }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [anchorEl, setAnchorEl] = useState(null); // For filter dropdown
  const [selectedFilter, setSelectedFilter] = useState(""); // Store selected filter

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = (filterValue) => {
    setSelectedFilter(filterValue);
    setAnchorEl(null);
  };

  const handleResetPass = (empid) => {
    console.log(empid);
    const data = { empid: empid };
    resetPassword(data);
  };

  // Filter users based on search term and selected filter
  const filteredUsers = emplistData.filter((user) => {
    const fullName = `${user.empName || ""}`.toLowerCase();
    const matchesSearch =
      (user.userid || "").toLowerCase().includes(searchTerm.toLowerCase()) ||
      fullName.includes(searchTerm.toLowerCase()) ||
      (user.email || "").toLowerCase().includes(searchTerm.toLowerCase()) ||
      (user.mobileNumber || "").includes(searchTerm); // Assuming mobileNumber can be empty but not null

    const matchesFilter = selectedFilter ? user.role === selectedFilter : true; // Filter based on department

    return matchesSearch && matchesFilter;
  });

  const highlightText = (text) => {
    if (!searchTerm) return text;
    const parts = (text || "").split(new RegExp(`(${searchTerm})`, "gi"));
    return parts.map((part, index) =>
      part.toLowerCase() === searchTerm.toLowerCase() ? (
        <span key={index} style={{ backgroundColor: "#FFEA00" }}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  return (
    <div>
      <Typography variant="h6" sx={{ mb: 1, color: "grey" }}>
        Ticket Management > Team List
      </Typography>
      <Box sx={{ p: 1 }}>
        <Paper
          elevation={3}
          sx={{
            width: "100%",
            p: 3,
            borderRadius: "12px",
            border: "2px solid #027BFE",
            borderRadius: "8px",
          }}
        >
          {" "}
          <Typography variant="h4" gutterBottom>
            Team List
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 3,
            }}
          >
            <Typography variant="h6">
              All Users ({filteredUsers.length})
            </Typography>

            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "white",
                  borderRadius: 1,
                  p: "2px 10px",
                  boxShadow: "0 1px 3px rgba(0, 0, 0, 0.2)",
                  mr: 2,
                  width: "300px",
                }}
              >
                <InputBase
                  sx={{ flex: 1, ml: 1 }}
                  placeholder="Search Here"
                  inputProps={{ "aria-label": "search users" }}
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                <IconButton type="submit" aria-label="search">
                  <SearchIcon />
                </IconButton>
              </Box>

              <Button
                variant="contained"
                startIcon={<FilterListIcon />}
                sx={{
                  backgroundColor: "#1976d2",
                  color: "white",
                  textTransform: "none",
                }}
                onClick={handleFilterClick}
              >
                Filter
              </Button>
              {/* Dropdown for filters */}
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => handleFilterClose("")}
              >
                <MenuItem onClick={() => handleFilterClose("")}>None</MenuItem>
                <MenuItem onClick={() => handleFilterClose("TICKETHEAD")}>
                  TICKETHEAD
                </MenuItem>
                <MenuItem onClick={() => handleFilterClose("ACCOUNTS")}>
                  ACCOUNTS
                </MenuItem>
                <MenuItem onClick={() => handleFilterClose("SERVICES")}>
                  SERVICES
                </MenuItem>
                <MenuItem onClick={() => handleFilterClose("OTHERS")}>
                  OTHERS
                </MenuItem>
              </Menu>
            </Box>
          </Box>
          <TableContainer>
            <Table>
              <TableHead sx={{ backgroundColor: "#1976d2" }}>
                <TableRow>
                  <TableCell sx={{ color: "white" }}>Emp No</TableCell>
                  <TableCell sx={{ color: "white" }}>Employee Name</TableCell>
                  <TableCell sx={{ color: "white" }}>Mobile Number</TableCell>
                  <TableCell sx={{ color: "white" }}>Email</TableCell>
                  <TableCell sx={{ color: "white" }}>
                    Department (UType)
                  </TableCell>
                  <TableCell sx={{ color: "white" }}>
                    Reset user Password
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredUsers.map((user, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      backgroundColor: index % 2 === 0 ? "#EFF6FF" : "#BFDBFE",
                    }}
                  >
                    <TableCell>{highlightText(user.empNo || "")}</TableCell>
                    <TableCell>{highlightText(user.empName || "")}</TableCell>
                    <TableCell>{highlightText(user.mobile || "")}</TableCell>
                    <TableCell>{highlightText(user.email || "")}</TableCell>
                    <TableCell>{highlightText(user.role || "")}</TableCell>
                    <TableCell>
                      <LoadingButton
                        onClick={() => {
                          handleResetPass(user.empId);
                        }}
                      >
                        Reset Password
                      </LoadingButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </div>
  );
};

export default TeamList;
