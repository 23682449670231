import React, { useState } from "react";
import {
  Grid,
  Box,
  Typography,
  Button,
  Avatar,
  Container,
  CircularProgress,
  Stack,
  TextField,
} from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik } from "formik";
import * as Yup from "yup";
import loginImage from "../../res/images/loginImage.png";
import { HIDDEN_PASSWORD, VISIBLE_PASSWORD } from "../../res/images";
import EDSOM_LOGO from "../../res/images/image.png";

const LogIn = ({ onHandleLogin }) => {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleLogin = async (values, { setSubmitting }) => {
    setLoading(true);
    try {
      await onHandleLogin(values);
    } catch (error) {
      console.error("Login failed! Please check your credentials.");
    } finally {
      setLoading(false);
      setSubmitting(false);
    }
  };

  const validationSchema = Yup.object({
    mobile: Yup.string()
      .matches(/^\d{10}$/, "Mobile number must be 10 digits")
      .required("Mobile number is required"),
    password: Yup.string().required("Password is required"),
  });

  return (
    <Grid container style={{ height: "100vh" }}>
      {/* Left Panel - Image Section */}
      <Grid item xs={12} md={6} style={leftPanelStyle}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <img src={loginImage} alt="Login" style={{ maxWidth: "80%" }} />
        </Box>
      </Grid>

      {/* Right Panel - Login Form */}
      <Grid item xs={12} md={6} style={rightPanelStyle}>
        <Container maxWidth="xs">
          <ToastContainer />
          <Stack spacing={3} alignItems="center">
            {/* Logo */}
            <Avatar src={EDSOM_LOGO} sx={{ width: 120, height: 120 }} />
            <Typography variant="h4" sx={titleStyle}>
              Welcome to <br /> Ipaisa TechSupport Portal
            </Typography>

            <Formik
              initialValues={{ mobile: "", password: "" }}
              validationSchema={validationSchema}
              onSubmit={handleLogin}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                errors,
                touched,
              }) => (
                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  sx={{ width: "100%" }}
                >
                  {/* Mobile Number */}
                  <Typography sx={labelStyle}>
                    Employee Mobile Number
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="mobile"
                    placeholder="Enter Mobile Number"
                    value={values.mobile}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.mobile && errors.mobile)}
                    helperText={touched.mobile && errors.mobile}
                  />

                  {/* Password */}
                  <Typography sx={labelStyle}>Password</Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                    sx={{ position: "relative" }}
                  >
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="password"
                      placeholder="Enter Password"
                      type={showPassword ? "text" : "password"}
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.password && errors.password)}
                      helperText={touched.password && errors.password}
                    />
                    <Box
                      sx={passwordIconStyle}
                      onClick={handlePasswordVisibility}
                      aria-label="toggle password visibility"
                    >
                      {showPassword ? (
                        <VISIBLE_PASSWORD />
                      ) : (
                        <HIDDEN_PASSWORD />
                      )}
                    </Box>
                  </Box>

                  {/* Forgot Password */}
                  <Typography sx={forgotPasswordStyle}>
                    Forgot Password? Contact Your Ticket Head..!!
                  </Typography>

                  {/* Login Button */}
                  <Button
                    type="submit"
                    fullWidth
                    disabled={loading}
                    sx={loginButtonStyle}
                  >
                    {loading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      "Login"
                    )}
                  </Button>

                  {/* Remember Me */}
                  <Box display="flex" alignItems="center" mt={2}>
                    <input
                      type="checkbox"
                      id="remember"
                      style={checkboxStyle}
                    />
                    <label htmlFor="remember" style={{ marginLeft: "10px" }}>
                      Remember Me
                    </label>
                  </Box>
                </Box>
              )}
            </Formik>

            {/* Footer Message */}
            <Typography variant="body2" align="center" sx={{ marginTop: 2 }}>
              New to{" "}
              <Typography
                component="span"
                sx={{ color: "#027BFE", fontWeight: "bold" }}
              >
                Edsom Fintech?
              </Typography>{" "}
              Contact TICKETHEAD To Get Your Login Credentials.
            </Typography>
          </Stack>
        </Container>
      </Grid>
    </Grid>
  );
};

// Styles
const leftPanelStyle = {
  backgroundColor: "#027BFE",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const rightPanelStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "20px",
  flexDirection: "column",
  backgroundColor: "#f9f9f9",
};

const titleStyle = {
  textAlign: "center",
  fontWeight: 600,
  fontSize: "28px",
  marginBottom: "20px",
};

const labelStyle = {
  fontWeight: "bold",
  marginBottom: "5px",
  marginTop: "10px",
  textAlign: "left",
};

const passwordIconStyle = {
  position: "absolute",
  right: 10,
  top: "50%",
  transform: "translateY(-50%)",
  cursor: "pointer",
};

const forgotPasswordStyle = {
  color: "#FF6F61",
  fontWeight: 500,
  marginTop: "15px",
  textAlign: "center",
};

const loginButtonStyle = {
  marginTop: "20px",
  height: "50px",
  fontWeight: "bold",
  backgroundColor: "#027BFE",
  color: "#fff",
};

const checkboxStyle = {
  width: "16px",
  height: "16px",
};

export default LogIn;
