// import {
//   DASHBOARD_ICON,
//   TICKET_ICON,
//   TEAM_ICON,
//   REPORT_ICON,
// } from "../res/images";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import ExpandLessIcon from "@mui/icons-material/ExpandLess";

// export const menuData = [
//   {
//     id: 1,
//     title: "Dashboard",
//     icon: () => (
//       <DASHBOARD_ICON style={{ width: 20, height: 15, marginLeft: 10 }} />
//     ),
//     path: "/",
//   },
//   {
//     id: 2,
//     title: "Ticket Management ",
//     icon: () => <TICKET_ICON style={{ width: 35, height: 35 }} />,
//     path: "/ticket-management",

//     submenu: [
//       {
//         title: "Open Tickets",
//         path: "/Opentickets",
//       },
//       {
//         title: "Closed Tickets",
//         path: "/closedtickets",
//       },
//       {
//         title: "Pending Tickets",
//         path: "/pendingtickets",
//       },
//       // {
//       //   title: "Priority Tickets",
//       //   path: "/prioritytickets",
//       // },

//       {
//         title: "Ticket Assign",
//         path: "/ticketassign",
//       },
//     ],
//   },

//   {
//     id: 3,
//     title: "Team Management",
//     icon: () => <TEAM_ICON style={{ width: 35, height: 35 }} />,
//     path: "/team-management",

//     submenu: [
//       {
//         title: "Team List",
//         path: "/teamlist",
//       },

//       {
//         title: "Add Employee",
//         path: "/addemployee",
//       },
//     ],
//   },
//   {
//     id: 4,
//     title: "Report & Analytics",
//     icon: () => <REPORT_ICON style={{ width: 35, height: 35 }} />,
//     path: "/report-analytics",
//   },
// ];

import {
  DASHBOARD_ICON,
  TICKET_ICON,
  TEAM_ICON,
  REPORT_ICON,
  CHARGEBACK_ICON,
} from "../res/images";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useState } from "react";
import { MoneyOff } from "@mui/icons-material";

export const Menu = () => {
  // State to track which menu is expanded
  const [expandedMenu, setExpandedMenu] = useState(null);

  const toggleSubmenu = (menuId) => {
    // Toggle the menu based on its ID
    if (expandedMenu === menuId) {
      setExpandedMenu(null); // Collapse the menu if it's already expanded
    } else {
      setExpandedMenu(menuId); // Expand the selected menu
    }
  };

  return (
    <div>
      {menuData.map((menuItem) => (
        <div key={menuItem.id}>
          <div
            onClick={() => {
              if (menuItem.submenu) toggleSubmenu(menuItem.id);
            }}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between", // Align items to the edges
              cursor: "pointer",
              padding: "10px 15px", // Add padding for better spacing
            }}
          >
            {/* Left part: Icon and Title */}
            <div style={{ display: "flex", alignItems: "center" }}>
              {menuItem.icon()}
              <span style={{ marginLeft: "10px" }}>{menuItem.title}</span>
            </div>

            {/* Right part: Expand/Collapse icon (shown if submenu exists) */}
            {menuItem.submenu &&
              (expandedMenu === menuItem.id ? (
                <ExpandLessIcon />
              ) : (
                <ExpandMoreIcon />
              ))}
          </div>

          {/* Render submenu if it's expanded */}
          {menuItem.submenu && expandedMenu === menuItem.id && (
            <div style={{ marginLeft: "20px" }}>
              {menuItem.submenu.map((submenuItem, index) => (
                <div key={index} style={{ padding: "5px 0" }}>
                  <a href={submenuItem.path}>{submenuItem.title}</a>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export const menuData = [
  {
    id: 1,
    title: "Dashboard",
    icon: () => (
      <DASHBOARD_ICON style={{ width: 20, height: 15, marginLeft: 10 }} />
    ),
    path: "/",
  },
  {
    id: 2,
    title: "Ticket Management",
    icon: () => <TICKET_ICON style={{ width: 35, height: 35 }} />,
    path: "/ticket-management",
    submenu: [
      {
        title: "Waiting Tickets",
        path: "/ApprovedTickets",
      },
      {
        title: "Open Tickets",
        path: "/Opentickets",
      },
      {
        title: "Ongoing Tickets",
        path: "/Ongoingtickets",
      },
      {
        title: "Completed Tickets",
        path: "/Completedtickets",
      },
      {
        title: "Rejected Tickets",
        path: "/Rejectedtickets",
      },
      {
        title: "Assign Tickets",
        path: "/ticketassign",
      },
    ],
  },
  {
    id: 3,
    title: "Team Management",
    icon: () => <TEAM_ICON style={{ width: 35, height: 35 }} />,
    path: "/team-management",
    submenu: [
      {
        title: "Team List",
        path: "/teamlist",
      },
      {
        title: "Add Employee",
        path: "/addemployee",
      },
    ],
  },
  {
    id: 4,
    title: "Charge Back",
    icon: () => <CHARGEBACK_ICON style={{ width: 35, height: 35 }} />,
    submenu: [
      {
        title: "Set ChargeBack",
        path: "/setchgback",
      },
      {
        title: "ChargeBack List",
        path: "/chgbacklist",
      },
      {
        title: "ChargeBack Allotement List",
        path: "/chgbackallot",
      },
      {
        title: "Zone-wise Users",
        path: "/countbyuser",
      },
    ],
  },
  // {
  //   id: 4,
  //   title: "Report & Analytics",
  //   icon: () => <REPORT_ICON style={{ width: 35, height: 35 }} />,
  //   path: "/report-analytics",
  // },
];
