import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { colors } from "../res/colors";
import { BELL_ICON, EDSOM_LOGO } from "../res/images";
import { useNavigate } from "react-router-dom";

export default function DropdownMenu({
  profileOpen,
  setProfileOpen,
  notiOpen,
  setNotiOpen,
  openProfile,
  openNoti,
  onHandleProfileOpen,
  onHandleNotiOpen,
  onHandleProfileClose,
  onHandleNotiClose,
  onHandleLogout,
}) {
  const navigate = useNavigate(); // Initialize the navigation function

  // Function to handle profile navigation
  const handleProfileNavigation = () => {
    navigate("/userdetail");
    onHandleProfileClose(); // Close the profile dropdown
  };

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        {/* <Typography sx={{ minWidth: 100 }}>Contact</Typography> */}
        {/* <Typography sx={{ minWidth: 100 }}>Profile</Typography> */}
        {/* <Tooltip title="Notifications">
          <IconButton
            onClick={onHandleNotiOpen}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={openNoti ? "notification-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openNoti ? "true" : undefined}
          >
            <Avatar
              sx={{ width: 32, height: 32, backgroundColor: colors.whiteColor }}
            >
              <BELL_ICON />
            </Avatar>
          </IconButton>
        </Tooltip> */}
        <Tooltip title="Account settings">
          <IconButton
            onClick={onHandleProfileOpen}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={openProfile ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openProfile ? "true" : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }}>
              <EDSOM_LOGO style={{ width: "100%", height: "100%" }} />
            </Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={notiOpen}
        id="notification-menu"
        open={openNoti}
        onClose={onHandleNotiClose}
        onClick={onHandleNotiClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={onHandleNotiClose}>
          <Avatar /> notifications this is a text or
          typographyyyyyyyyyyyyyyyyyyyyyyyyyyyyy
        </MenuItem>
        <Divider />
        <MenuItem onClick={onHandleNotiClose}>
          <Avatar /> My account
        </MenuItem>
        {/* <Divider /> */}
      </Menu>
      <Menu
        anchorEl={profileOpen}
        id="account-menu"
        open={openProfile}
        onClose={onHandleProfileClose}
        onClick={onHandleProfileClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleProfileNavigation}>
          <Avatar /> Profile
        </MenuItem>
        {/* <MenuItem onClick={onHandleProfileClose}>
          <Avatar /> My account
        </MenuItem> */}
        <Divider />
        {/* <MenuItem onClick={onHandleProfileClose}>
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          Add another account
        </MenuItem> */}
        <MenuItem onClick={onHandleProfileClose}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem onClick={onHandleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
