import React from "react";
import LoginPage from "../pages/LoginPage";
import SideDrawerPage from "../pages/SideDrawerPage";
import { useSelector } from "react-redux";
// import localData from "../helper/localData";
// import { persistor } from "../stores";

const RootRoute = () => {
  // useEffect(() => {
  //   // localData.removeToken();
  //   persistor.purge().then(() => {
  //     console.log("Store has been purged!");
  //   });
  // }, []);
  const token = useSelector((state) => state.loginSlice.loginData.message);
  // const tok = localData.getIsLogin();
  // console.log(tok, "This is token");
  // console.log(token, "This is token");
  // const token = false;
  return token ? <SideDrawerPage /> : <LoginPage />;
};

export default RootRoute;
