import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ticketWaitingStats } from "../stores/dashboardStores/dashboardSlice";
import TicketWaitinglast24hours from "../components/dashboard/Tickettable";

const TickettablePage = ({ ...props }) => {
  const dispatch = useDispatch();
  // console.log(props.id+"kljhgf");
  const waitingTicketData = useSelector(
    (state) => state.dashboardSlice.ticketWaitingStatsData
  );

  useEffect(() => {
    dispatch(ticketWaitingStats());
  }, [dispatch]);

  console.log(props, "this is table");
  return (
    <div>
      <TicketWaitinglast24hours waitingTicketsData={waitingTicketData} />
    </div>
  );
};

export default TickettablePage;
