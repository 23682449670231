import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Box, Typography, TablePagination } from "@mui/material";

// Styling for table rows and cells
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#EFF6FF", // Lighter row color
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#BFDBFE", // Darker row color
  },
  // Ensure uniform row height
  height: "50px",
}));

const TableHeaderCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#027BFE", // Background color for table header
  color: "#FFFFFF", // Text color for table header
  fontWeight: "bold",
  fontSize: "14px", // Adjusted font size for consistency
  padding: "12px", // Consistent padding for the header
  textAlign: "center", // Center-align header text
}));

const TableCellStyled = styled(TableCell)(({ theme }) => ({
  padding: "8px", // Consistent padding for table cells
  fontSize: "14px", // Adjust font size for consistency
  textAlign: "center", // Center-align cell content
}));

export default function TicketWaitinglast24hours({ waitingTicketsData }) {
  const location = useLocation();
  const { id, title } = location.state || {};

  const getTicketsByStatus = () => {
    console.log(waitingTicketsData, "laskdn");
    switch (id) {
      case 1:
        return waitingTicketsData?.waitingTickets || [];
      case 2:
        return waitingTicketsData?.completedTickets || [];
      case 3:
        return waitingTicketsData?.pendingTickets || [];
      case 4:
        return waitingTicketsData?.escalatedTickets || [];
      default:
        return [];
    }
  };

  const filteredTickets = getTicketsByStatus();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedTickets = filteredTickets.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <div>
      <Typography
        variant="h6"
        sx={{ mb: 1, color: "grey", marginBottom: "2%" }}
      >
        Ticket Management > {title}
      </Typography>
      <Paper elevation={3} sx={{ borderRadius: "12px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: 16,
          }}
        >
          <Typography sx={{ fontWeight: "bold", fontSize: "20px" }}>
            Last 24 Hours Table
          </Typography>
        </div>
        <Box sx={{ p: 2 }}>
          <TableContainer component={Paper} sx={{ borderRadius: "12px" }}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="tickets table"
            >
              <TableHead>
                <TableRow>
                  <TableHeaderCell>ID</TableHeaderCell>
                  <TableHeaderCell>Remark</TableHeaderCell>
                  <TableHeaderCell>Issue Type</TableHeaderCell>
                  <TableHeaderCell>Subject</TableHeaderCell>
                  <TableHeaderCell>Payment Mode</TableHeaderCell>
                  <TableHeaderCell>Status</TableHeaderCell>
                  <TableHeaderCell>Date</TableHeaderCell>
                  <TableHeaderCell>User ID</TableHeaderCell>
                  {/* <TableHeaderCell>Parent</TableHeaderCell> */}
                  <TableHeaderCell>Amount</TableHeaderCell>
                  <TableHeaderCell>Service Name</TableHeaderCell>
                  {/* <TableHeaderCell>Parent Name</TableHeaderCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedTickets.length > 0 ? (
                  paginatedTickets.map((item) => (
                    <StyledTableRow key={item.id}>
                      <TableCellStyled>{item.id}</TableCellStyled>
                      <TableCellStyled>{item.remark}</TableCellStyled>
                      <TableCellStyled>{item.issueType}</TableCellStyled>
                      <TableCellStyled>{item.subject}</TableCellStyled>
                      <TableCellStyled>
                        {item.paymentMode || "N/A"}
                      </TableCellStyled>
                      <TableCellStyled>{item.status}</TableCellStyled>
                      <TableCellStyled>
                        {item.date
                          ? new Date(item.date).toLocaleString()
                          : "N/A"}
                      </TableCellStyled>
                      <TableCellStyled>
                        {item.userid || "N/A"}
                      </TableCellStyled>
                      {/* <TableCellStyled>{item.parent || "N/A"}</TableCellStyled> */}
                      <TableCellStyled>
                        {item.amount !== null ? item.amount : "N/A"}
                      </TableCellStyled>
                      <TableCellStyled>
                        {item.service_name || "N/A"}
                      </TableCellStyled>
                      {/* <TableCellStyled>
                        {item.parentName || "N/A"}
                      </TableCellStyled> */}
                    </StyledTableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={12} align="center">
                      No tickets available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {/* Pagination component */}
          <TablePagination
            component="div"
            count={filteredTickets.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Paper>
    </div>
  );
}
