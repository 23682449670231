import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  emplist,
  resetPassword,
} from "../../stores/teammangementStores/teammanagementSlice";
import TeamList from "../../components/team management/TeamList";

const TeamListPage = () => {
  const dispatch = useDispatch();

  const emplistData = useSelector(
    (state) => state.teammanagementSlice.emplistData
  );

  const resetPasswordPage = (payload) => {
    dispatch(resetPassword(payload));
  };

  useEffect(() => {
    dispatch(emplist());
  }, [dispatch]);

  return (
    <div>
      <TeamList emplistData={emplistData} resetPassword={resetPasswordPage} />
    </div>
  );
};

export default TeamListPage;
