import React from "react";
import { persistor } from "../stores";
import localData from "../helper/localData";
import { useDispatch } from "react-redux";
import { logoutAction } from "../stores/authStore/authSlice";
import DropdownMenu from "../components/DropdownMenu";
// import { logoutAction } from "../stores/authStore/authSlice";

const DropdownMenuPage = () => {
  const dispatch = useDispatch();
  const [profileOpen, setProfileOpen] = React.useState(null);
  const [notiOpen, setNotiOpen] = React.useState(null);
  const openProfile = Boolean(profileOpen);
  const openNoti = Boolean(notiOpen);
  const onHandleProfileOpen = (event) => {
    setProfileOpen(event.currentTarget);
  };
  const onHandleNotiOpen = (event) => {
    setNotiOpen(event.currentTarget);
  };
  const onHandleProfileClose = () => {
    setProfileOpen(null);
  };
  const onHandleNotiClose = () => {
    setNotiOpen(null);
  };
  const onHandleLogout = () => {
    localData.removeID();
    localData.removeUsertype();
    // localData.removeToken();
    dispatch(logoutAction());
    persistor.purge().then(() => {
      console.log("Store has been purged!");
    });
  };
  return (
    <DropdownMenu
      profileOpen={profileOpen}
      setProfileOpen={setProfileOpen}
      notiOpen={notiOpen}
      setNotiOpen={setNotiOpen}
      openProfile={openProfile}
      openNoti={openNoti}
      onHandleProfileOpen={onHandleProfileOpen}
      onHandleNotiOpen={onHandleNotiOpen}
      onHandleProfileClose={onHandleProfileClose}
      onHandleNotiClose={onHandleNotiClose}
      onHandleLogout={onHandleLogout}
    />
  );
};

export default DropdownMenuPage;
