import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  styled,
  Typography,
  Box,
  TableContainer,
  TablePagination,
  InputBase,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

// Styled components for the table head and body rows
const StyledTableHead = styled(TableHead)(() => ({
  backgroundColor: "#027BFE",
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#EFF6FF",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#BFDBFE",
  },
}));

const ClosedTickets = ({ openTicketData }) => {
  const [searchTerm, setSearchTerm] = useState(""); // State for search term
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [showSearchBar, setShowSearchBar] = useState(false); // State to toggle search bar

  const handleSearchToggle = () => {
    setShowSearchBar(!showSearchBar);
    setSearchTerm(""); // Clear search term when toggling search bar
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Highlight the matching search term in the text
  const highlightText = (text, highlight) => {
    if (!text) return ""; // Fallback for null or undefined values
    if (!highlight) return text;
    const parts = text.split(new RegExp(`(${highlight})`, "gi"));
    return parts.map((part, index) =>
      part.toLowerCase() === highlight.toLowerCase() ? (
        <span key={index} style={{ backgroundColor: "yellow" }}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  // Filter data based on search term across multiple fields
  const filteredData = openTicketData.filter((row) => {
    return [
      "id",
      "remark",
      "issueType",
      "subject",
      "status",
      "parentName",
      "team",
      "userid",
      new Date(row.date).toLocaleString(),
      new Date(row.date_completion).toLocaleDateString(),
    ].some((key) =>
      String(row[key] || row.userid)
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
  });

  // Calculate the data to display for the current page
  const paginatedData = filteredData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <div>
      <Typography
        variant="h6"
        sx={{ mb: 1, color: "grey", marginBottom: "2%" }}
      >
        Ticket Management > Rejected Tickets
      </Typography>
      <Paper
        elevation={3}
        sx={{
          border: "2px solid #027BFE", // Blue border
          borderRadius: "8px", // Optional: for rounded corners
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: 16,
          }}
        >
          <Typography sx={{ fontWeight: "bold", fontSize: "20px" }}>
            Rejected Tickets Table
          </Typography>
          {showSearchBar ? (
            <InputBase
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearchChange}
              sx={{
                border: "1px solid grey",
                borderRadius: "4px",
                paddingLeft: "8px",
                width: "300px",
              }}
              endAdornment={
                <IconButton onClick={handleSearchToggle}>
                  <HighlightOffIcon />
                </IconButton>
              }
            />
          ) : (
            <IconButton onClick={handleSearchToggle}>
              <SearchIcon />
            </IconButton>
          )}
        </div>
        <Box sx={{ p: 2 }}>
          <TableContainer sx={{ borderRadius: "20px 20px 0px 0px" }}>
            <Table>
              <StyledTableHead>
                <TableRow>
                  <TableCell sx={{ color: "white" }}>ID</TableCell>
                  <TableCell sx={{ color: "white" }}>Remark</TableCell>
                  <TableCell sx={{ color: "white" }}>Issue Type</TableCell>
                  <TableCell sx={{ color: "white" }}>Subject</TableCell>
                  <TableCell sx={{ color: "white" }}>Status</TableCell>
                  <TableCell sx={{ color: "white" }}>Date</TableCell>
                  <TableCell sx={{ color: "white" }}>User ID</TableCell>
                  <TableCell sx={{ color: "white" }}>Team</TableCell>
                  <TableCell sx={{ color: "white" }}>Track By Emp No</TableCell>
                  <TableCell sx={{ color: "white" }}>
                    Track By Emp Name
                  </TableCell>
                  <TableCell sx={{ color: "white" }}>
                    Last Updated Date
                  </TableCell>
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {paginatedData.map((row) => (
                  <StyledTableRow key={row.id}>
                    <TableCell>
                      {highlightText(String(row.id), searchTerm)}
                    </TableCell>
                    <TableCell>
                      {highlightText(row.remark, searchTerm)}
                    </TableCell>
                    <TableCell>
                      {highlightText(row.issueType, searchTerm)}
                    </TableCell>
                    <TableCell>
                      {highlightText(row.subject, searchTerm)}
                    </TableCell>
                    <TableCell>
                      {highlightText(row.status, searchTerm)}
                    </TableCell>
                    <TableCell>
                    {highlightText(
                        row.date ? new Date(row.date).toLocaleString() : "N/A"
                      )}
                    </TableCell>
                    <TableCell>
                      {highlightText(row.userid, searchTerm)}
                    </TableCell>

                    <TableCell>{highlightText(row.team, searchTerm)}</TableCell>
                    <TableCell>
                      {highlightText(
                        row.trackBy ? row.trackBy.empNo : "N/A",
                        searchTerm
                      )}
                    </TableCell>
                    <TableCell>
                      {highlightText(
                        row.trackBy ? row.trackBy.empName : "N/A",
                        searchTerm
                      )}
                    </TableCell>
                    <TableCell>
                    {highlightText(
                        row.date_completion ? new Date(row.date_completion).toLocaleString() : "N/A"
                      )}
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Pagination Controls */}
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(event, newPage) => setPage(newPage)}
            onRowsPerPageChange={(event) => {
              setRowsPerPage(parseInt(event.target.value, 10));
              setPage(0); // Reset to first page when rows per page changes
            }}
          />
        </Box>
      </Paper>
    </div>
  );
};

export default ClosedTickets;
