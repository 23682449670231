import React from "react";
import { useDispatch } from "react-redux";
import { registerEmp } from "../../stores/teammangementStores/teammanagementSlice";
import AddEmployee from "../../components/team management/AddEmployee";

const AddemployeePage = () => {
  const dispatch = useDispatch();

  const departmentData = [
    {
      dataValue: 1,
      label: "TICKETHEAD",
    },
    {
      dataValue: 2,
      label: "ACCOUNTS",
    },
    {
      dataValue: 3,
      label: "SERVICES",
    },
    {
      dataValue: 4,
      label: "OTHERS",
    },
  ];

  const onHandleSubmit = (values) => {
    console.log("Form values:", values);
    const updatedValues = { ...values, utype: values.utype - 1 };

    console.log("Updated form values:", updatedValues);
    // Dispatch the Redux action
    dispatch(registerEmp(updatedValues));
  };

  const onSubmitForm = (val) => {
    console.log(val, "This is val");
  };

  console.log(departmentData, "Data in page");
  return (
    <AddEmployee
      onHandleSubmit={onHandleSubmit}
      onSubmitForm={onSubmitForm}
      departmentData={departmentData}
    />
  );
};

export default AddemployeePage;
