import {
  Box,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

// Register required components for Chart.js
ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = ({ tableStatsData }) => {
  const [tableStats, setTableStats] = useState([
    { id: 0, label: "Offline Bank Funds", overallTickets: 0, pending: 0 },
    { id: 1, label: "Info Issue", overallTickets: 0, pending: 0 },
    { id: 2, label: "Payment Issue", overallTickets: 0, pending: 0 },
    { id: 3, label: "Service Issue", overallTickets: 0, pending: 0 },
    { id: 4, label: "Other Issue", overallTickets: 0, pending: 0 },
  ]);

  useEffect(() => {
    if (tableStatsData) {
      const updatedTableStats = tableStats.map((stat) => {
        const issueData = tableStatsData[stat.label];
        return {
          ...stat,
          overallTickets: issueData?.overallTickets || stat.overallTickets,
          pending: issueData?.pending || stat.pending,
        };
      });
      setTableStats(updatedTableStats);
    }
  }, [tableStatsData]);

  const pieStats = tableStats.map((stat, index) => ({
    id: index,
    value: stat.overallTickets,
    color: ["#00C49F", "#0088FE", "#FFBB28", "#FF8042", "#FF6384"][index],
  }));

  // Calculate total overall tickets
  const totalOverallTickets = tableStats.reduce(
    (acc, row) => acc + row.overallTickets,
    0
  );

  // Data for react-chartjs-2 Doughnut chart
  const chartData = {
    labels: tableStats.map((row) => row.label),
    datasets: [
      {
        data: pieStats.map((item) => item.value),
        backgroundColor: pieStats.map((item) => item.color),
        hoverBackgroundColor: pieStats.map((item) => item.color),
        borderWidth: 1,
      },
    ],
  };

  const options = {
    cutout: "70%", // This creates the doughnut effect
    plugins: {
      legend: {
        display: false, // Disable the chart legend
      },
      doughnutCenterText: {
        text: totalOverallTickets,
      },
    },
  };

  // Custom plugin to draw "Tickets" and totalOverallTickets in the center of the doughnut chart
  const doughnutCenterText = {
    id: "doughnutCenterText",
    beforeDraw: function (chart) {
      const { ctx, width, height } = chart;
      const totalTickets = chart.config.options.plugins.doughnutCenterText.text;
      const text1 = "Tickets"; // First line text
      const text2 = totalTickets; // Second line text (total overall tickets)

      ctx.save();

      // Set general text alignment
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";

      // Set font size for "Tickets"
      ctx.font = "16px sans-serif"; // Set static font size
      // ctx.fillStyle = "#666"; // Set text color
      const text1Y = height / 2 - 25; // Position "Tickets" slightly above center
      ctx.fillText(text1, width / 2, text1Y); // Center "Tickets" horizontally

      // Set font size for total overall tickets
      ctx.font = "20px sans-serif"; // Set static font size
      const text2Y = height / 2 + 10; // Position totalTickets slightly below center
      ctx.fillText(text2, width / 2, text2Y); // Center totalTickets horizontally

      ctx.restore();
    },
  };

  // Register the custom plugin
  ChartJS.register(doughnutCenterText);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="50vh"
    >
      <Card sx={{ width: "100%", maxWidth: 1200 }}>
        <CardContent>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            {/* Doughnut Chart title */}
            <Typography variant="h6" component="div" fontWeight="600">
              Pie Chart
            </Typography>
          </Box>

          {/* Flex container for doughnut chart and table */}
          <Box
            display="flex"
            flexDirection={{ xs: "column", md: "row" }}
            justifyContent="center"
            alignItems="flex-start"
            sx={{ overflow: "auto", scrollbarWidth: "none" }}
          >
            {/* Doughnut Chart Area */}
            <Box display="flex" justifyContent="center" padding={2} flex={1}>
              <Doughnut data={chartData} options={options} />
            </Box>

            {/* Legend Table Area */}
            <Box padding={2} flex={1}>
              <TableContainer
                style={{ maxHeight: 400, scrollbarWidth: "none" }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Label</TableCell>
                      <TableCell align="right">Overall Tickets</TableCell>
                      <TableCell align="right">Pending</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableStats.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell>
                          <Box display="flex" alignItems="center">
                            <Box
                              sx={{
                                width: 16,
                                height: 16,
                                borderRadius: "50%",
                                backgroundColor:
                                  pieStats[row.id]?.color || "#000",
                                marginRight: 1,
                              }}
                            />
                            <Typography sx={{ textDecoration: "none" }}>
                              {row.label}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell align="right">
                          {row.overallTickets}
                        </TableCell>
                        <TableCell align="right">{row.pending}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default PieChart;
