import { Card, CardContent, Typography } from "@mui/material";
import React from "react";

const SummaryCard = ({ title, value, color, onClick }) => {
  return (
    <Card
      variant="outlined"
      sx={{
        backgroundColor: "#fff",
        cursor: onClick ? "pointer" : "default",
        textAlign: "center",
        border: `2px solid ${color || "#007BFF"}`, // Change border color to blue
        borderRadius: 2, // Optional: Adjust border radius if needed
      }}
      onClick={onClick}
    >
      <CardContent>
        <Typography variant="h3" component="div">
          {value}
        </Typography>
        <Typography variant="body1">{title}</Typography>
      </CardContent>
    </Card>
  );
};

export default SummaryCard;
