import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { loginApi } from "./authApi";
import localData from "../../helper/localData";

// Define the async thunk
export const login = createAsyncThunk("login", async (payload) => {
  try {
    const response = await loginApi(payload);
    // console.log(response, "Response inside Login Api");
    // localData.setToken(response.token);empName
    localData.setID(response.empId);
    localData.setIsLogin(true);
    localData.setUsertype(response.userType);
    localData.setEmpName(response.empName);
    return response;
  } catch (error) {
    console.log(error, "Error inside LOGIN API");
    throw new Error(error);
  }
});

// Define the initial state
const initialState = {
  loginData: [], // Adjust initial state according to actual types
  loginLoading: false,
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    logoutAction: (state) => {
      state.loginData = []; // Clear the token when logging out
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loginLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loginLoading = false;
        state.loginData = action.payload;
      })
      .addCase(login.rejected, (state) => {
        state.loginLoading = false;
      });
  },
});

export const { logoutAction } = loginSlice.actions;
export default loginSlice.reducer;
