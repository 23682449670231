import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  changeCHGStatusAPI,
  freezeTXNAPI,
  getAllotementListAPI,
  getCHGListAPI,
  getCountByUserAPI,
  searchTXNAPI,
} from "./chgApi";

export const searchTXN = createAsyncThunk("searchTXN", async (payload) => {
  console.log(payload, "payload in slice");
  const response = await searchTXNAPI(payload);
  console.log(response, "slice response");
  return response;
});

export const freezeTXN = createAsyncThunk("freezeTXN", async (payload) => {
  console.log(payload, "payload in slice");
  const response = await freezeTXNAPI(payload);
  console.log(response, "slice response");
  return response;
});

export const getCHGList = createAsyncThunk("getCHGList", async (payload) => {
  console.log(payload, "payload in slice");
  const response = await getCHGListAPI(payload);
  console.log(response, "slice response");
  return response;
});

export const getCHGAllocationList = createAsyncThunk(
  "getCHGAllocationList",
  async () => {
    const response = await getAllotementListAPI();
    console.log(response, "slice response");
    return response;
  }
);

export const changeStatus = createAsyncThunk(
  "changeStatus",
  async (payload) => {
    console.log(payload, "payload in slice");
    const response = await changeCHGStatusAPI(payload);
    console.log(response, "slice response");
    return response;
  }
);

export const getCountByUser = createAsyncThunk("getCountByUser", async () => {
  const response = await getCountByUserAPI();
  console.log(response, "slice response");
  return response;
});

const initialState = {
  seachData: [],
  searchLoading: false,
  freezeData: [],
  freezeLoading: false,
  chgListData: [],
  chgListLoading: false,
  allocationListData: [],
  allocationListLoading: false,
  changeStatusData: [],
  changeStatusLoading: false,
  getCountByUserData: [],
  getCountByUserLoading: false,
};

export const chgSlice = createSlice({
  name: "charge_back_Slice",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(searchTXN.pending, (state) => {
        state.searchLoading = true;
      })
      .addCase(searchTXN.fulfilled, (state, action) => {
        state.searchLoading = false;
        console.log(action, "what is action");
        state.seachData = action.payload;
      })
      .addCase(searchTXN.rejected, (state, action) => {
        state.searchLoading = false;
        console.log(action, "what is action");
        state.seachData = action.payload
          ? action.payload.message
          : "An unknown error occurred";
      })
      .addCase(freezeTXN.pending, (state) => {
        state.freezeLoading = true;
      })
      .addCase(freezeTXN.fulfilled, (state, action) => {
        state.freezeLoading = false;
        state.freezeData = action.payload;
      })
      .addCase(freezeTXN.rejected, (state, action) => {
        state.freezeLoading = false;
        state.freezeData = action.payload;
      })
      .addCase(getCHGList.pending, (state) => {
        state.chgListLoading = true;
      })
      .addCase(getCHGList.fulfilled, (state, action) => {
        state.chgListLoading = false;
        state.chgListData = action.payload?.data;
        console.log(action);
      })
      .addCase(getCHGList.rejected, (state, action) => {
        state.chgListLoading = false;
        state.chgListData = action.payload;
      })
      .addCase(getCHGAllocationList.pending, (state) => {
        state.allocationListLoading = true;
      })
      .addCase(getCHGAllocationList.fulfilled, (state, action) => {
        state.allocationListLoading = false;
        state.allocationListData = action.payload?.data;
      })
      .addCase(getCHGAllocationList.rejected, (state, action) => {
        state.allocationListLoading = false;
        state.allocationListData = action.payload;
      })
      .addCase(changeStatus.pending, (state) => {
        state.changeStatusLoading = true;
      })
      .addCase(changeStatus.fulfilled, (state, action) => {
        state.changeStatusLoading = false;
        state.changeStatusData = action.payload;
        console.log(action);
      })
      .addCase(changeStatus.rejected, (state, action) => {
        state.changeStatusLoading = false;
        state.changeStatusData = action.payload;
      })
      .addCase(getCountByUser.pending, (state) => {
        state.getCountByUserLoading = true;
      })
      .addCase(getCountByUser.fulfilled, (state, action) => {
        state.getCountByUserLoading = false;
        state.getCountByUserData = action.payload?.data;
        console.log(action);
      })
      .addCase(getCountByUser.rejected, (state, action) => {
        state.getCountByUserLoading = false;
        state.getCountByUserData = action.payload;
      });
  },
});

export default chgSlice.reducer;
