import React from 'react'
import { colors } from '../../res/colors'

const CommonSelect = ({onSelect,name,id,onChange, onBlur,value,data,style}) => {
  // console.log(data,"This is data inside common select")
  return (
    <select name={name} 
    onChange={onChange}
    onBlur={onBlur}
    value={value}
    onSelect={onSelect}

    id={id} style={{padding:'3%',borderRadius:5, borderColor: colors.inputTextBorderColor,borderWidth: 1,
        ...style,
    }}>
        <option value="" disabled>
        Select an option
      </option>
    {data ? data.map((item)=> {
        return (
            
            <option value={item.dataValue}>{item.label}</option>

        )
    }) : null}
   
    </select>  )
}

export default CommonSelect