import React from "react";
import { colors } from "../../../res/colors";

const CommonInput = ({
  value,
  style,
  type,
  pleaceHolder,
  id,
  onChange,
  onBlur,
}) => {
  return (
    <input
      type={type ? type : "input"}
      placeholder={
        pleaceHolder ? pleaceHolder : "set a placeholder - {placeHolder}"
      }
      id={id}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      style={{
        padding: "3%",
        borderRadius: 5,
        borderColor: colors.inputTextBorderColor,

        borderWidth: 1,
        ...style,
      }}
    />
  );
};

export default CommonInput;
