import React, { useState } from "react";
import "./NewTicket.css"; // Import the CSS file
import { UPLOADIMG_ICON } from "../../res/images";
import { toast, ToastContainer } from "react-toastify";

const CreateTicket = () => {
  const [ticketName, setTicketName] = useState("");
  const [issueType, setIssueType] = useState("");
  const [priority, setPriority] = useState("");
  const [subject, setSubject] = useState("");
  const [requester, setRequester] = useState("");
  const [assignee, setAssignee] = useState("");
  const [remark, setRemark] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  // Handlers
  const handleTicketNameChange = (event) => setTicketName(event.target.value);
  const handleIssueTypeChange = (event) => setIssueType(event.target.value);
  const handlePriorityChange = (event) => setPriority(event.target.value);
  const handleSubjectChange = (event) => setSubject(event.target.value);
  const handleRequesterChange = (event) => setRequester(event.target.value);
  const handleAssigneeChange = (event) => setAssignee(event.target.value);
  const handleRemarkChange = (event) => setRemark(event.target.value);

  const handleFileChange = (event) => {
    if (event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
      console.log(event.target.files[0]); // Handle the selected file as needed
    }
  };

  const handleCancel = () => {
    setTicketName("");
    setIssueType("");
    setPriority("");
    setSubject("");
    setRequester("");
    setAssignee("");
    setRemark("");
    setSelectedFile(null);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Validation: Check if all fields are filled
    if (
      !ticketName ||
      !issueType ||
      !priority ||
      !subject ||
      !requester ||
      !assignee ||
      !remark
    ) {
      toast.error("Please fill in all required fields.");
      return;
    }

    console.log({
      ticketName,
      issueType,
      priority,
      subject,
      requester,
      assignee,
      remark,
      selectedFile,
    });

    toast.success("Ticket Raised Successfully");
  };

  return (
    <div className="container">
      <h2>Create New Ticket</h2>
      <form className="form-container" onSubmit={handleSubmit}>
        <div className="form-row">
          <div className="form-field">
            <label htmlFor="ticketName" className="form-label">
              Ticket Name
            </label>
            <input
              type="text"
              id="ticketName"
              className="form-input"
              value={ticketName}
              onChange={handleTicketNameChange}
            />
          </div>
          <div className="form-field">
            <label htmlFor="issueType" className="form-label">
              Issue Type
            </label>
            <select
              id="issueType"
              className="form-input"
              value={issueType}
              onChange={handleIssueTypeChange}
            >
              <option>Select Issue Type</option>
              <option value="Bug">Bug</option>
              <option value="Feature">Feature</option>
            </select>
          </div>
        </div>

        <p className="priority-heading">Priority</p>
        <div className="priority-container">
          <div
            className={`priority-box priority-low ${priority === "Low" ? "selected-priority" : ""
              }`}
            onClick={() => setPriority("Low")} // Manually set the priority on click
          >
            <input
              type="radio"
              id="lowPriority"
              name="priority"
              value="Low"
              checked={priority === "Low"}
              onChange={handlePriorityChange}
            />
            <label htmlFor="lowPriority">
              <span></span> Low
            </label>
          </div>
          <div
            className={`priority-box priority-medium ${priority === "Medium" ? "selected-priority" : ""
              }`}
            onClick={() => setPriority("Medium")} // Manually set the priority on click
          >
            <input
              type="radio"
              id="mediumPriority"
              name="priority"
              value="Medium"
              checked={priority === "Medium"}
              onChange={handlePriorityChange}
            />
            <label htmlFor="mediumPriority">
              <span></span> Medium
            </label>
          </div>
          <div
            className={`priority-box priority-high ${priority === "High" ? "selected-priority" : ""
              }`}
            onClick={() => setPriority("High")} // Manually set the priority on click
          >
            <input
              type="radio"
              id="highPriority"
              name="priority"
              value="High"
              checked={priority === "High"}
              onChange={handlePriorityChange}
            />
            <label htmlFor="highPriority">
              <span></span> High
            </label>
          </div>
        </div>

        <div className="form-row">
          <div className="form-field">
            <label htmlFor="subject" className="form-label">
              Subject
            </label>
            <input
              type="text"
              id="subject"
              className="form-input"
              value={subject}
              onChange={handleSubjectChange}
            />
          </div>
          <div className="form-field">
            <label htmlFor="requester" className="form-label">
              Requester
            </label>
            <input
              type="text"
              id="requester"
              className="form-input"
              value={requester}
              onChange={handleRequesterChange}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-field">
            <label htmlFor="assignee" className="form-label">
              Assignee
            </label>
            <input
              type="text"
              id="assignee"
              className="form-input"
              value={assignee}
              onChange={handleAssigneeChange}
            />
          </div>
        </div>

        <div className="form-field">
          <label htmlFor="remark" className="form-label">
            Remark
          </label>
          <textarea
            id="remark"
            className="form-input"
            value={remark}
            onChange={handleRemarkChange}
          ></textarea>
        </div>

        <div className="form-row">
          <div className="form-field">
            <label htmlFor="fileUpload" className="form-label">
              Upload Img <UPLOADIMG_ICON />
            </label>
            <input
              type="file"
              id="fileUpload"
              accept="image/*"
              className="form-input"
              onChange={handleFileChange}
            />
          </div>
        </div>

        <div className="button-container">
          <button
            type="button"
            className="cancel-button"
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button type="submit" className="create-button">
            Create
          </button>
        </div>
      </form>

      <ToastContainer />
    </div>
  );
};

export default CreateTicket;




