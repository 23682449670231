// import { combineReducers, configureStore } from "@reduxjs/toolkit";
// import React from "react";
// import dashboardSlice from "./dashboardStores/dashboardSlice";

import { combineReducers, configureStore } from "@reduxjs/toolkit";
import dashboardSlice from "./dashboardStores/dashboardSlice";
import loginSlice from "./authStore/authSlice";
import ticketmanagementSlice from "./ticketmanagementStores/ticketmanagementSlice";
import teammanagementSlice from "./teammangementStores/teammanagementSlice";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { chgSlice } from "./chargebackStores/chgSlice";

// const rootReducer = combineReducers({
//   dashboardSlice: dashboardSlice,
// });

// const store = configureStore({
//   reducer: rootReducer,
// });

// export { store };
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["loginSlice"],
};

const rootReducer = combineReducers({
  loginSlice: loginSlice,
  dashboardSlice: dashboardSlice,
  ticketmanagementSlice: ticketmanagementSlice,
  teammanagementSlice: teammanagementSlice,
  chgSlice: chgSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          "persist/PERSIST",
          "persist/REHYDRATE",
          "persist/FLUSH",
          "persist/PAUSE",
          "persist/PURGE",
          "persist/REGISTER",
          "getCHGList/fulfilled",
          "getCHGAllocationList/fulfilled",
          "getCountByUser/fulfilled",
        ],
      },
    }),
});
export const persistor = persistStore(store);
export { store };
