// import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import RootRoute from "./routes/index";
import { Provider } from "react-redux";
import { store } from "./stores";
import ToastifyContainer from "./components/common/Notify";

function App() {
  return (
    // <p>Hello</p>
    <Provider store={store}>
      <BrowserRouter>
        <ToastifyContainer />
        <RootRoute />
      </BrowserRouter>
    </Provider>
  );
}

export default App;
