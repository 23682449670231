import React from "react";
import edsomLogo from "../images/image.png";
import passwordHidden from "../images/passwordHidden.png";
import passwordVisible from "../images/passwordVisible.png";
import settingicon from "../images/settings-icon.png";
import bellicon from "../images/bell-icon.png";
import usericon from "../images/user-image.png";
import dashboardicon from "../images/dashboardlogo.png";
import teamicon from "../images/teamM.png";
import ticketicon from "../images/ticketM.png";
import reporticon from "../images/report.png";
import ipaisalogo from "../images/iPaisaLogo.jpg";
import uploadimgicon from "../images/uploadimage.svg";
import { MoneyOff } from "@mui/icons-material";

// Define the EDSOM_LOGO component
export const EDSOM_LOGO = ({ style, onClick, id }) => (
  <img
    src={edsomLogo}
    alt="logo"
    style={{ ...style }}
    onClick={onClick}
    id={id}
  />
);

// Define the HIDDEN_PASSWORD component
export const HIDDEN_PASSWORD = ({ style, onClick, id }) => (
  <img
    src={passwordHidden}
    alt="hiddenPassword"
    style={{ ...style }}
    onClick={onClick}
    id={id}
  />
);

// Define the VISIBLE_PASSWORD component
export const VISIBLE_PASSWORD = ({ style, onClick, id }) => (
  <img
    src={passwordVisible}
    alt="visiblePassword"
    style={{ ...style }}
    onClick={onClick}
    id={id}
  />
);

export const SETTING_ICON = ({ style, onClick, id }) => (
  <img
    src={settingicon}
    alt="settingIcon"
    style={{ ...style }}
    onClick={onClick}
    id={id}
  />
);

export const BELL_ICON = ({ style, onClick, id }) => (
  <img
    src={bellicon}
    alt="bellicon"
    style={{ ...style }}
    onClick={onClick}
    id={id}
  />
);

export const USER_ICON = ({ style, onClick, id }) => (
  <img
    src={usericon}
    alt="usericon"
    style={{ ...style }}
    onClick={onClick}
    id={id}
  />
);

export const TEAM_ICON = ({ style, onClick, id }) => (
  <img
    src={teamicon}
    alt="teamicon"
    style={{ ...style }}
    onClick={onClick}
    id={id}
  />
);

export const TICKET_ICON = ({ style, onClick, id }) => (
  <img
    src={ticketicon}
    alt="ticketicon"
    style={{ ...style }}
    onClick={onClick}
    id={id}
  />
);

export const DASHBOARD_ICON = ({ style, onClick, id }) => (
  <img
    src={dashboardicon}
    alt="dashboardicon"
    style={{ ...style }}
    onClick={onClick}
    id={id}
  />
);

export const REPORT_ICON = ({ style, onClick, id }) => (
  <img
    src={reporticon}
    alt="reporticon"
    style={{ ...style }}
    onClick={onClick}
    id={id}
  />
);

export const IPAISA_LOGO = ({ style, onClick, id }) => (
  <img
    src={ipaisalogo}
    alt="ipaisalogo"
    style={{ ...style }}
    onClick={onClick}
    id={id}
  />
);

export const UPLOADIMG_ICON = ({ style, onClick, id }) => (
  <img
    src={uploadimgicon}
    alt="uploadimgicon"
    style={{ ...style }}
    onClick={onClick}
    id={id}
  />
);

export const CHARGEBACK_ICON = ({ style, onClick, id }) => (
  <>
    <MoneyOff />
  </>
);
