import { Search } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Container,
  Divider,
  Grid2,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { toast } from "react-toastify";

const ChargeBack = ({ onSearchTXN, onFreezeAmount, loader, freezeLoader }) => {
  const [searchText, setSearchText] = useState("");
  const [txnFound, settxnFound] = useState("");
  const [transactionData, setTransactionData] = useState({
    amount: null,
    status: null,
    txnDate: null,
    txnType: null,
    txnid: null,
    user: null,
    chargeBackAmount: null,
  });

  const resetTransactionData = () => {
    setTransactionData({
      amount: null,
      status: null,
      txnDate: null,
      txnType: null,
      txnid: null,
      user: null,
      chargeBackAmount: null,
    });
  };

  console.log(loader);
  const handleSearchClick = async () => {
    settxnFound("");
    resetTransactionData();
    if (!searchText) {
      alert("Please enter a value to search.");
      return;
    }
    const response = await onSearchTXN(searchText);

    if (response.payload.data) {
      console.log(response.payload.data, "got resposne");
      setTransactionData(response.payload.data);
    } else {
      console.log("error", response);
      settxnFound(
        response.payload.response.data.message
          ? response.payload.response.data.message
          : "Something Went Wrong..!!"
      );
    }
  };

  const handleInputChange = (e) => {
    const { value } = e.target;

    // Allow only valid float numbers or an empty string
    if (/^\d*\.?\d*$/.test(value)) {
      setTransactionData((prevData) => ({
        ...prevData,
        chargeBackAmount: value, // Update only if the value is valid
      }));
    }
  };

  const onFreezeAmt = async () => {
    const { amount, status, txnDate, txnType, txnid, user, chargeBackAmount } =
      transactionData;

    // Check if all required fields are present
    if (
      !amount ||
      !status ||
      !txnDate ||
      !txnType ||
      !txnid ||
      !user ||
      !chargeBackAmount ||
      chargeBackAmount <= 0
    ) {
      toast.error("All Fields are mandatory");
      return;
    } else {
      const response = await onFreezeAmount(transactionData);
      console.log(response);
      if (response.payload.status === 200) {
        toast.success(response.payload.data.message);
        settxnFound("");
        resetTransactionData();
      } else {
        toast.error("Something went wrong..!!");
      }
    }
  };

  return (
    <>
      <Container maxWidth="xl">
        <Typography
          sx={{ mb: 1, color: "grey", marginBottom: "2%", marginLeft: "2%" }}
        >
          ChargeBack {">"} New Charge Back
        </Typography>
        <Paper
          elevation={3}
          style={{
            borderRadius: "8px",
            border: "2px solid #027BFE",
            margin: "2%",
            padding: "2%",
          }}
        >
          <Typography variant="h5">Set New ChargeBack</Typography>
          <TextField
            variant="standard"
            sx={{ width: "100%" }}
            label="Transaction ID"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)} // Update state on input change
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position="end">
                    <Search
                      onClick={handleSearchClick}
                      style={{ cursor: "pointer" }}
                    />
                  </InputAdornment>
                ),
              },
            }}
          />
          {loader && (
            <>
              <Typography>Loading.....{loader}</Typography>{" "}
            </>
          )}

          {transactionData.amount && (
            <Container sx={{ marginTop: 2 }}>
              <Typography>Transaction Found :</Typography>
              <Divider></Divider>
              <Grid2 container spacing={1}>
                <Grid2 size={{ xs: 12, sm: 6 }}>
                  <Typography>
                    Tranaction Amount : {transactionData.amount}
                  </Typography>
                </Grid2>
                <Grid2 size={{ xs: 12, sm: 6 }}>
                  <Typography>
                    Tranaction Status : {transactionData.status}
                  </Typography>
                </Grid2>
                <Grid2 size={{ xs: 12, sm: 6 }}>
                  <Typography>
                    Tranaction Date :{" "}
                    {transactionData.txnDate
                      ? new Date(transactionData.txnDate).toLocaleString()
                      : "N/A"}
                  </Typography>
                </Grid2>
                <Grid2 size={{ xs: 12, sm: 6 }}>
                  <Typography>
                    Tranaction Type : {transactionData.txnType}
                  </Typography>
                </Grid2>
                <Grid2 size={{ xs: 12, sm: 6 }}>
                  <Typography>
                    Tranaction ID : {transactionData.txnid}
                  </Typography>
                </Grid2>
                <Grid2>
                  <Typography>User : {transactionData.user}</Typography>
                </Grid2>
                <Grid2 size={{ xs: 12, sm: 6 }}>
                  <TextField
                    sx={{ width: "100%" }}
                    label="Set Charge Back Amount"
                    value={transactionData.chargeBackAmount || ""} // Set the current value
                    onChange={handleInputChange} // Update the state on change
                  />
                </Grid2>
                <Grid2 size={{ xs: 12, sm: 6 }}>
                  <LoadingButton
                    loading={freezeLoader}
                    variant="outlined"
                    size="large"
                    onClick={onFreezeAmt}
                  >
                    Freeze Amount
                  </LoadingButton>
                </Grid2>
              </Grid2>
            </Container>
          )}
          {txnFound && (
            <Container sx={{ marginTop: 2 }}>
              <Typography sx={{ color: "red" }}>{txnFound}</Typography>
            </Container>
          )}
        </Paper>
      </Container>
    </>
  );
};

export default ChargeBack;
