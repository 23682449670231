import React, { useEffect, useState } from "react";
import ChargeBack from "../../components/ChargeBackComp/ChargeBack";
import { useDispatch, useSelector } from "react-redux";
import { freezeTXN, searchTXN } from "../../stores/chargebackStores/chgSlice";

const ChargeBackPage = () => {
  const dispatch = useDispatch();
  const txnloading = useSelector((state) => state.chgSlice.searchLoading);

  const freezeLoading = useSelector((state) => state.chgSlice.freezeLoading);

  const onSearch = async (values) => {
    console.log(values, "got value");
    const headers = {
      txnid: values, // Add txnid header with the value
      "Content-Type": "application/json", // Add any other necessary headers
    };
    try {
      const response = await dispatch(searchTXN({ headers }));
      console.log(response, "got response in page");
      return response;
    } catch (error) {
      console.log(error, "got error in page");
      return error;
    }
  };

  const onFreeze = async (values) => {
    console.log(values, "got value");

    try {
      const response = await dispatch(freezeTXN(values));
      console.log(response, "got response in page");
      return response;
    } catch (error) {
      console.log(error, "got error in page");
      return error;
    }
  };

  useEffect(() => {
    console.log(txnloading);
  }, [txnloading]);

  return (
    <ChargeBack
      onSearchTXN={onSearch}
      loader={txnloading}
      onFreezeAmount={onFreeze}
      freezeLoader={freezeLoading}
    />
  );
};

export default ChargeBackPage;
