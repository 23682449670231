import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  emplistApi,
  filterbyteamApi,
  registerempApi,
  resetPasswordApi,
} from "./teammanagementApi";

export const registerEmp = createAsyncThunk("registerEmp", async (payload) => {
  console.log(payload, "payload inside slice");
  const response = await registerempApi(payload);
  console.log(response, "response  of register emp in slice");
  return response;
});

export const emplist = createAsyncThunk("emplist", async (payload) => {
  const response = await emplistApi(payload);
  console.log(response, "response of emp list in slice");
  return response;
});

export const resetPassword = createAsyncThunk(
  "resetpassword",
  async (payload) => {
    const response = await resetPasswordApi(payload);
    console.log(response, "response of emp list in slice");
    return response;
  }
);

export const filterbyteam = createAsyncThunk(
  "filterbyteam",
  async (payload) => {
    const response = await filterbyteamApi(payload);
    console.log(response, "response of filterbyteam in slice");
    return response;
  }
);

const initialState = {
  registerEmpData: [],
  registerEmpLoading: false,
  emplistData: [],
  emplistLoading: false,
  resetpassData: [],
  resetpassLoading: false,
  filterbyteamData: [],
  filterbyteamLoading: false,
};

export const teammanagementSlice = createSlice({
  name: "team_management_Slice",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(registerEmp.pending, (state) => {
        state.registerEmpLoading = true;
      })
      .addCase(registerEmp.fulfilled, (state, action) => {
        state.registerEmpLoading = false;
        state.registerEmpData = action.payload;
      })
      .addCase(registerEmp.rejected, (state) => {
        state.registerEmpLoading = false;
      })
      .addCase(emplist.pending, (state) => {
        state.emplistLoading = true;
      })
      .addCase(emplist.fulfilled, (state, action) => {
        state.emplistLoading = false;
        state.emplistData = action.payload;
      })
      .addCase(emplist.rejected, (state) => {
        state.emplistLoading = false;
      })
      .addCase(resetPassword.pending, (state) => {
        state.resetpassLoading = true;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.resetpassLoading = false;
        state.resetpassData = action.payload;
      })
      .addCase(resetPassword.rejected, (state) => {
        state.resetpassLoading = false;
      })
      .addCase(filterbyteam.pending, (state) => {
        state.filterbyteamLoading = true;
      })
      .addCase(filterbyteam.fulfilled, (state, action) => {
        state.filterbyteamLoading = false;
        state.filterbyteamData = action.payload;
      })
      .addCase(filterbyteam.rejected, (state) => {
        state.filterbyteamLoading = false;
      });
  },
});

export default teammanagementSlice.reducer;
