import {
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

const CountByUser = ({ loader, listData = [] }) => {
  console.log(listData);

  useEffect(() => {}, [loader]);

  // Function to determine the color based on chargeBackCount
  const getCellStyle = (chargeBackCount) => {
    if (chargeBackCount === 0) {
      return { color: "green" }; // Green Zone
    } else if (chargeBackCount <= 5) {
      return { color: "blue" }; // Blue Zone
    } else if (chargeBackCount <= 15) {
      return { color: "gold" }; // Yellow Zone
    } else {
      return { color: "red" }; // Red Zone
    }
  };

  return (
    <>
      <Container maxWidth="xl">
        <Typography
          sx={{ mb: 1, color: "grey", marginBottom: "2%", marginLeft: "2%" }}
        >
          ChargeBack {">"} Zone Wise User List
        </Typography>
        <Paper
          elevation={3}
          sx={{
            width: "100%",
            p: 3,
            borderRadius: "12px",
            border: "2px solid #027BFE",
            borderRadius: "8px",
          }}
        >
          {loader && <Typography variant="h3">Loading...</Typography>}
          <TableContainer>
            <Table>
              <TableHead sx={{ backgroundColor: "#1976d2" }}>
                <TableRow>
                  <TableCell sx={{ color: "white" }}>Sr No</TableCell>
                  <TableCell sx={{ color: "white" }}>UserId</TableCell>
                  <TableCell sx={{ color: "white" }}>First Name</TableCell>
                  <TableCell sx={{ color: "white" }}>Middle Name</TableCell>
                  <TableCell sx={{ color: "white" }}>Last Name</TableCell>
                  <TableCell sx={{ color: "white" }}>
                    Charge Back Count
                  </TableCell>
                  <TableCell sx={{ color: "white" }}>ChargeBack Zone</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listData.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={7} style={{ textAlign: "center" }}>
                      No Transactions
                    </TableCell>
                  </TableRow>
                ) : (
                  listData.map((txn, i) => {
                    // Determine style for chargeBackZone
                    const cellStyle = getCellStyle(txn.chargeBackCount);
                    return (
                      <TableRow
                        key={i}
                        sx={{
                          backgroundColor: i % 2 === 0 ? "#EFF6FF" : "#BFDBFE",
                        }}
                      >
                        <TableCell>{i + 1}</TableCell>
                        <TableCell>{txn.userId}</TableCell>
                        <TableCell>{txn.firstname}</TableCell>
                        <TableCell>{txn.middlename}</TableCell>
                        <TableCell>{txn.lastname}</TableCell>
                        <TableCell>{txn.chargeBackCount}</TableCell>
                        <TableCell sx={cellStyle}>
                          {txn.chargeBackCount === 0
                            ? "Green Zone"
                            : txn.chargeBackCount <= 5
                            ? "Blue Zone"
                            : txn.chargeBackCount <= 15
                            ? "Yellow Zone"
                            : "Red Zone"}
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Container>
    </>
  );
};

export default CountByUser;
