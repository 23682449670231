import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DASHBOARD_ICON,
  IPAISA_LOGO,
  REPORT_ICON,
  TEAM_ICON,
  TICKET_ICON,
} from "../res/images";
import { menuData } from "./MenuData";
import MenuList from "./MenuList";
import PrivateRoutes from "../routes/PrivateRoute";
import DropdownMenuPage from "../pages/DropdownMenuPage";
import { useState } from "react";
import { useEffect } from "react";
import localData from "../helper/localData";
import { Typography } from "@mui/material";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // marginLeft: `-${drawerWidth}px`,
    marginLeft: open ? `-${drawerWidth}px` : `0`,
    variants: [
      {
        props: ({ open }) => open,
        style: {
          transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          }),
          marginLeft: 0,
        },
      },
    ],
  })
);

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

// const closedMixin = (theme) => ({
//   transition: theme.transitions.create("width", {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   overflowX: "hidden",
//   width: `calc(${theme.spacing(7)} + 1px)`,
//   [theme.breakpoints.up("sm")]: {
//     width: `calc(${theme.spacing(8)} + 1px)`,
//   },
// });

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: 0, // Set the width to 0 when closed to hide the sidebar completely
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  height: "auto",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Sidebar() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [userType, setUserType] = useState("");
  const [filteredMenuData, setFilteredMenuData] = useState([]);

  // Map of paths to page names
  const pageNames = {
    "/": "Dashboard",
    "/newticket": "Create New Ticket",
    "/ticketreassign": "Re-assign Ticket",
    "/priorityticket": "High Priority Tickets",
    "/ApprovedTickets": "Waiting for Approval",
    "/Opentickets": "Open Status Tickets",
    "/Ongoingtickets": "Ongoing Status Tickets",
    "/Completedtickets": "Completed Tickets",
    "/Rejectedtickets": "Rejected Tickets",
    "/ticketassign": "Assign Tickets",
    "/tickets": "Tickets by Status",
    "/summary": "Tickets by Issue",
    "/teamlist": "Team List",
    "/addemployee": "Add New Employee",
    "/userdetail": "Profile",
    "/setchgback": "New Charge Back",
    "/chgbacklist": "Charge Back List",
    "/chgbackallot": "ChargeBack Allotement List",

    // Add more routes as needed
  };

  // Get the page name based on the current path
  const pageName = pageNames[location.pathname] || "Tech Support";

  const handleDrawerToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  // Map of icons to menu items
  const iconMap = {
    Dashboard: (
      <DASHBOARD_ICON style={{ width: 20, height: 15, marginLeft: 10 }} />
    ),
    "Ticket Management": <TICKET_ICON style={{ width: 35, height: 35 }} />,
    "Team Management": <TEAM_ICON style={{ width: 35, height: 35 }} />,
    "Report & Analytics": <REPORT_ICON style={{ width: 35, height: 35 }} />,
  };

  useEffect(() => {
    // Retrieve userType from localStorage
    const userType = localData.getUsertype();

    // Filter the menuData
    const allowedMenuData = menuData
      .map((menuItem) => {
        if (menuItem.id === 2) {
          // Filter submenu for Ticket Management
          const filteredSubmenu = menuItem.submenu.filter((submenuItem) => {
            if (submenuItem.title === "Assign Tickets") {
              return userType === "TICKETHEAD"; // Only show "Ticket Assign" for TICKETHEAD
            }
            return true; // Show other submenu items for all users
          });
          // Return the updated menu item with the filtered submenu
          return { ...menuItem, submenu: filteredSubmenu };
        }

        if (menuItem.id === 3) {
          // Only show "Team Management" for ADMIN and TICKETHEAD
          return userType === "ADMIN" || userType === "TICKETHEAD"
            ? menuItem
            : null;
        }
        if (menuItem.id === 3) {
          // Only show "Team Management" for ADMIN and TICKETHEAD
          return userType === "ADMIN" ||
            userType === "TICKETHEAD" ||
            userType === "ACCOUNTS"
            ? menuItem
            : null;
        }

        return menuItem; // Return other menu items unchanged
      })
      .filter(Boolean); // Remove any null entries (e.g., "Team Management" for non-ADMIN or non-TICKETHEAD)

    // Set the filtered menu data
    setFilteredMenuData(allowedMenuData);
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        sx={{ backgroundColor: "white", boxShadow: "none" }}
      >
        <Toolbar>
          {!open && (
            <IconButton
              color="inherit"
              onClick={handleDrawerToggle}
              edge="start"
              sx={{ marginRight: 5, color: "black" }}
            >
              <MenuIcon />
            </IconButton>
          )}

          <Typography
            variant="h6"
            sx={{ color: "black", whiteSpace: "nowrap" }}
          >
            {pageName}
          </Typography>

          <Box sx={{ flexGrow: 1 }} />

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              alignItems: "center",
            }}
          >
            {/* <Typography variant="h6" noWrap component="div">
            Dashboard
          </Typography> */}
            {/* <DASHBOARD_NOTIFICATION style={{height:'5%', width:'1.5%', marginRight:'3%'}}/> */}
            <DropdownMenuPage />
            {/* <EDSOM_LOGO style={{height:'10%', width:'3%'}}/> */}
          </div>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0.8rem",
            boxSizing: "border-box",
          }}
        >
          {/* Logo */}
          <IPAISA_LOGO
            style={{
              maxWidth: "30%", // Adjust the width dynamically
              height: "100%",
            }}
          />
          {/* Close Button */}
          <IconButton
            sx={{ left: "30%", maxWidth: "50%" }}
            onClick={handleDrawerToggle}
          >
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>

        <Divider />
        <List>
          {filteredMenuData.map((text, index) => (
            <MenuList key={index} text={text} index={index} />
          ))}
        </List>
      </Drawer>
      <Main sx={{ backgroundColor: "#F4F5FA" }} open={open}>
        <DrawerHeader />
        {/* <Outlet /> */}
        <PrivateRoutes />
      </Main>
    </Box>
  );
}
