import React from "react";
import Login from "../components/auth/Login";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../stores/authStore/authSlice";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginData = useSelector((state) => state.loginSlice?.loginData);

  const onHandleLogin = async (data) => {
    try {
      const result = await dispatch(login(data)).unwrap(); // Assuming you're using Redux Toolkit
      console.log(result);
      if (result.message === "Login Successful") {
        // Check if login was successful (depends on your action logic)
        navigate("/"); // Navigate to home
      } else {
        console.error("Login failed:", result.message); // Handle login error
      }
    } catch (error) {
      console.error("An error occurred during login:", error);
    }
  };

  console.log(loginData, "This is Login Data in Page from Api");

  return (
    <>
      <Login onHandleLogin={onHandleLogin} />
    </>
  );
};

export default LoginPage;
