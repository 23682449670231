import { axiosCHG } from "../../helper/axios";
import {
  CHANGECHGSTATUS,
  CHGCOUNTBYUSER,
  FREEZE_AMOUNT,
  GETCHARGEBACK_LIST,
  GETCHARGEBACKALLCATION_LIST,
  SEARCH_TRANSACTION,
} from "./chgConstants";

export const searchTXNAPI = async (payload) => {
  console.log(payload, "payload on hitting API");
  // Extract headers and body if present in the payload
  const { headers } = payload;
  return axiosCHG.get(SEARCH_TRANSACTION, { headers });
};

export const freezeTXNAPI = async (payload) => {
  console.log(payload, "payload on hitting API");
  return axiosCHG.post(FREEZE_AMOUNT, payload);
};

export const getCHGListAPI = async (payload) => {
  const { headers } = payload;
  console.log(headers, "headers on hitting API");
  return axiosCHG.get(GETCHARGEBACK_LIST, { headers });
};

export const getAllotementListAPI = async () => {
  return axiosCHG.get(GETCHARGEBACKALLCATION_LIST);
};

export const changeCHGStatusAPI = async (payload) => {
  const { headers } = payload;
  console.log(headers, "headers on hitting API");
  return axiosCHG.post(CHANGECHGSTATUS, null, { headers });
};

export const getCountByUserAPI = async () => {
  return axiosCHG.get(CHGCOUNTBYUSER);
};
