import * as CoreAxios from "axios";
import localData from "./localData";
import { Notify } from "../components/common/Notify";

export const axios = CoreAxios.default.create({
  baseURL: "https://apisupport.ipaisa.co.in",
  headers: {
    "Content-Type": "application/json",
  },
});
export const axiosImage = CoreAxios.default.create({
  baseURL: "https://apisupport.ipaisa.co.in",
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

export const axiosCHG = CoreAxios.default.create({
  baseURL: "https://api.ipaisa.co.in",
  headers: {
    "Content-Type": "application/json",
  },
});

axios.interceptors.request.use(async (request) => {
  console.log(request, "This is my request");
  const authToken = localData.getID();
  if (authToken) {
    request.headers.empId = `${authToken}`;
  }
  return request;
});
axiosImage.interceptors.request.use(async (request) => {
  const authToken = localData.getID();
  console.log(authToken, "Token inside axios");

  if (authToken) {
    request.headers.empId = `${authToken}`;
  }
  return request;
});

axiosCHG.interceptors.response.use(
  async (response) => {
    console.log(response, "check axios");
    return response;
  },
  (error) => {
    console.log(error, "check axios error");
    Notify(
      error.response.data.message
        ? error.response.data.message
        : "Something went wrong..!!",
      "error"
    );
    return error;
  }
);

axios.interceptors.response.use(
  async (response) => {
    const { method } = response.config;
    console.log(response.status, "This is response in azios");
    // Inside response interceptorst
    // if (response.status === 201) {
    //   Notify(response.data.message, "success", "top-right");
    // }
    if (method !== "get") {
      console.log(response.data.message, "This is message after hiting API");
      Notify(response.data.message, "success", "top-right");
    }

    return response.data;
  },
  (error) => {
    if (error.response.status === 400) {
      Notify(error.response.data.message, "error", "top-right");
    }
    if (error.response.status === 401) {
      Notify(error.response.data.message, "error", "top-right");
    }
    if (error.response.status === 403) {
      Notify(error.response.data.message, "error", "top-right");
    }
    if (error.response) {
      // Server responded with a status other than 2xx
      console.log("Error Response:", error.response);
    } else if (error.request) {
      // Request was made but no response was received
      console.log("Error Request:", error.request);
    } else {
      // Something else happened while setting up the request
      console.log("Error Message:", error.message);
    }
    return Promise.reject(error);
    // console.log(error.response.status,"REsponse from error in axios")
  }
);
axiosImage.interceptors.response.use(
  async (response) => {
    // console.log(response,"Response in axios")
    const { method } = response.config;
    if (method !== "get") {
      // ToastAndroid.show("API hit Successfully", ToastAndroid.SHORT);
      // ShowSuccessMessage('API hit Successfully')
    }
    return response.data;
  },
  (error) => {
    if (error.response) {
      // Server responded with a status other than 2xx
      console.log("Error Response:", error.response);
    } else if (error.request) {
      // Request was made but no response was received
      console.log("Error Request:", error.request);
    } else {
      // Something else happened while setting up the request
      console.log("Error Message:", error.message);
    }
    return Promise.reject(error);
    // console.log(error.response.status,"REsponse from error in axios")
  }
);
