import { axios } from "../../helper/axios";
import {
  EMP_LIST,
  FILTER_BY_TEAM,
  REGISTER_EMP,
  RESET_PASSWORD,
} from "./teammanagementConstants";

export const registerempApi = async (payload) => {
  console.log(payload, "payload on hitting API");
  return axios.post(REGISTER_EMP, payload);
};

export const emplistApi = async () => {
  return axios.get(EMP_LIST);
};

export const resetPasswordApi = async (payload) => {
  console.log(payload, "payload on hitting API");
  return axios.post(RESET_PASSWORD + `/${payload.empid}`);
};

export const filterbyteamApi = async (payload) => {
  console.log(payload, "payload on hitting API");
  return axios.get(FILTER_BY_TEAM);
};
