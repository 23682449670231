import React, { useRef } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  TextField,
  Button,
} from "@mui/material";

const Profile = ({ userDetailsData, onHandleSubmit }) => {
  const formRef = useRef(null); // Create a ref to reference the form

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const password = formData.get("password");
    const confirmPassword = formData.get("confirmPassword");

    const val = {
      currentPassword: formData.get("currentPassword"),
      password,
      confirmPassword,
    };

    onHandleSubmit(val)
      .then((response) => {
        console.log(response, "check actual resp");
        if (
          response.payload &&
          response.payload.message === "Password Successfully Changed"
        ) {
          // Reset the form fields if the password change is successful
          if (formRef.current) {
            formRef.current.reset();
          }
        }
      })
      .catch((error) => {
        console.error("Error changing password", error);
      });
  };

  return (
    <Box
      sx={{
        padding: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: 3,
      }}
    >
      {/* User Profile Card */}
      <Card
        sx={{
          width: "100%",
          maxWidth: "800px",
          borderRadius: "15px",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
          transition: "transform 0.2s, box-shadow 0.2s",
          "&:hover": {
            transform: "scale(1.02)",
            boxShadow: "0 6px 20px rgba(0, 0, 0, 0.15)",
          },
        }}
      >
        <CardContent>
          <Typography
            variant="h5"
            sx={{
              fontWeight: "bold",
              marginBottom: "20px",
              textAlign: "center",
              color: "primary.main",
            }}
          >
            User Profile
          </Typography>
          <Grid container spacing={3}>
            {/* Employee Number */}
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" color="textSecondary">
                Employee Number
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: "medium" }}>
                {userDetailsData.empNo || "N/A"}
              </Typography>
            </Grid>

            {/* Name */}
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" color="textSecondary">
                Name
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: "medium" }}>
                {userDetailsData.name || "N/A"}
              </Typography>
            </Grid>

            {/* Mobile Number */}
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" color="textSecondary">
                Mobile Number
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: "medium" }}>
                {userDetailsData.mobile || "N/A"}
              </Typography>
            </Grid>

            {/* Email */}
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" color="textSecondary">
                Email
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: "medium" }}>
                {userDetailsData.email || "N/A"}
              </Typography>
            </Grid>

            {/* Department */}
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" color="textSecondary">
                Department
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: "medium" }}>
                {userDetailsData.role || "N/A"}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* Change Password Card */}
      <Card
        sx={{
          width: "100%",
          maxWidth: "800px",
          borderRadius: "15px",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <CardContent>
          <Typography
            variant="h5"
            sx={{
              fontWeight: "bold",
              marginBottom: "20px",
              textAlign: "center",
              color: "primary.main",
            }}
          >
            Change Password
          </Typography>
          <form ref={formRef} onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              {/* Current Password */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  label="Current Password"
                  type="password"
                  name="currentPassword"
                  required
                />
              </Grid>

              {/* New Password */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  label="New Password"
                  type="password"
                  name="password"
                  required
                />
              </Grid>

              {/* Confirm Password */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  label="Confirm Password"
                  type="password"
                  name="confirmPassword"
                  required
                />
              </Grid>

              {/* Submit Button */}
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Change Password
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Profile;
