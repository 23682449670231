import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  assignTicket,
  openTicket,
} from "../../stores/ticketmanagementStores/ticketmanagementSlice";
import TicketAssign from "../../components/ticketmanagement/TicketAssign";
import ClipLoader from "react-spinners/ClipLoader";

const TicketAssignPage = () => {
  const dispatch = useDispatch();

  const waitingTicketData = useSelector(
    (state) => state.ticketmanagementSlice.openTicketData
  );

  const isLoading = useSelector(
    (state) => state.ticketmanagementSlice.openTicketLoading
  );

  console.log(waitingTicketData, "waiting tickets list");
  const data = "WAITING";

  const onHandleSubmit = async (val) => {
    console.log(val, "VAlue inside page in hanlde page");
    // const ticketData = [val];
    dispatch(assignTicket(val)).then(() => {
      dispatch(openTicket(data));
    });
  };

  useEffect(() => {
    dispatch(openTicket(data));
  }, [dispatch]);

  return (
    <div>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <ClipLoader color="#027BFE" size={35} />
        </div>
      ) : (
        <TicketAssign
          onHandleSubmit={onHandleSubmit}
          openTicketData={waitingTicketData}
        />
      )}
    </div>
  );
};

export default TicketAssignPage;

// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   assignTicket,
//   openTicket,
// } from "../../stores/ticketmanagementStores/ticketmanagementSlice";
// import TicketAssign from "../../components/ticketmanagement/TicketAssign";
// import { CircularProgress } from "@mui/material";

// const TicketAssignPage = () => {
//   const dispatch = useDispatch();
//   const waitingTicketData = useSelector(
//     (state) => state.ticketmanagementSlice.openTicketData
//   );

//   const [loading, setLoading] = useState(false); // Loader state
//   const [filteredTickets, setFilteredTickets] = useState(waitingTicketData); // Local state to remove assigned tickets

//   const data = "WAITING";

//   const onHandleSubmit = async (val) => {
//     setLoading(true); // Show loader
//     const ticketData = [val];
//     await dispatch(assignTicket(ticketData));
//     setFilteredTickets((prevTickets) =>
//       prevTickets.filter((ticket) => ticket.id !== val.id)
//     ); // Remove the assigned ticket from the list
//     setLoading(false); // Hide loader
//   };

//   useEffect(() => {
//     dispatch(openTicket(data));
//   }, [dispatch]);

//   useEffect(() => {
//     setFilteredTickets(waitingTicketData); // Sync local tickets with Redux store data
//   }, [waitingTicketData]);

//   return (
//     <div>
//       {loading ? (
//         <CircularProgress /> // Show loader when loading
//       ) : (
//         <TicketAssign
//           onHandleSubmit={onHandleSubmit}
//           openTicketData={filteredTickets} // Use filtered tickets here
//         />
//       )}
//     </div>
//   );
// };

// export default TicketAssignPage;
