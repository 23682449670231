import React from 'react'
import { colors } from '../../../res/colors'

const CommonButton = ({value, style, id, onClick}) => {
  return (
    <input
              type="button"
              value={value}
              id={id}
              onClick={onClick}
              style={{
                backgroundColor: colors.primaryColor,
                color: colors.whiteColor,
                borderRadius: 5,
                borderWidth: 1,
                padding: "3%",
                ...style
              }}
            />
  )
}

export default CommonButton