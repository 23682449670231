// import axios from "axios";
import { axios } from "../../helper/axios";
import {
  CHANGE_PASSWORD,
  SUMMARY_CARDS_STATS,
  SUMMARY_STATS,
  TABLE_STATS,
  TICKET_STATS,
  TICKET_WAITING_STATS,
  USER_DETAIL,
} from "./dashboardConstants";
// import { SUMMARY_STATS } from "./DashboardConstants";

export const summaryStatsApi = async (payload) => {
  console.log(SUMMARY_STATS, "payload on hitting API");
  return axios.get(SUMMARY_STATS);
};

export const ticketStatsApi = async (payload) => {
  console.log(TICKET_STATS, "payload on hitting API");
  return axios.get(TICKET_STATS);
};

export const tableStatsApi = async (payload) => {
  console.log(TABLE_STATS, "payload tablestats on hitting API");
  return axios.get(TABLE_STATS);
};

export const ticketWaitingStatsApi = async (payload) => {
  console.log(
    TICKET_WAITING_STATS,
    "payload waiting last 24 hourse stats hitting API"
  );
  return axios.get(TICKET_WAITING_STATS);
};

export const summaryCardsApi = async (payload) => {
  console.log(SUMMARY_CARDS_STATS, "payload cards start hitting api");
  return axios.get(SUMMARY_CARDS_STATS);
};

export const userdeatilApi = async (payload) => {
  console.log(USER_DETAIL, "payload userdetails hitting Api");
  return axios.get(USER_DETAIL);
};

export const changePasswordApi = async (payload) => {
  console.log(CHANGE_PASSWORD, "payload changepassword hitting Api");
  return axios.post(CHANGE_PASSWORD, payload);
};
