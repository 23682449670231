import React from "react";
import { Route, Routes } from "react-router-dom";
// import Sidebar from "../components/sidenav/sidebar";
import NewTicket from "../components/TicketSection/NewTicket";
import TicketReassign from "../components/TicketSection/TicketReassign";
import PriorityTicket from "../components/TicketSection/PriorityTicket";
import DashboardPage from "../pages/DashboardPage";

import TickettablePage from "../pages/TickettablePage";
import SummaryCardtablePage from "../pages/SummaryCardtablePage";
import OpenticketsPage from "../pages/ticketmanagement/OpenticketsPage";
// import PriorityTickets from "../components/ticketmanagement/PriorityTickets";
import ClosedticketsPage from "../pages/ticketmanagement/ClosedticketsPage";
import PendingticketsPage from "../pages/ticketmanagement/PendingticketsPage";
import TicketAssignPage from "../pages/ticketmanagement/TicketAssignPage";
// import TeamList from "../components/team management/TeamList";
// import AddEmployee from "../components/team management/AddEmployee";
import AddemployeePage from "../pages/teammanagement/AddemployeePage";
import TeamListPage from "../pages/teammanagement/TeamListPage";
import CompletedticketsPage from "../pages/ticketmanagement/CompletedticketsPage";
import ApproveticketsPage from "../pages/ticketmanagement/ApproveticketsPage";
import ProfilePage from "../pages/profile/ProfilePage";
import ChargeBackPage from "../pages/ChargeBack/ChargeBackPage";
import ChargeBackListPage from "../pages/ChargeBack/ChargeBackListPage";
import AllotementListPage from "../pages/ChargeBack/AllotementListPage";
import CountByUserListPage from "../pages/ChargeBack/CountByUserListPage";
const PrivateRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<DashboardPage />} />
      <Route path="/newticket" element={<NewTicket />} />
      <Route path="/ticketreassign" element={<TicketReassign />} />
      <Route path="/priorityticket" element={<PriorityTicket />} />
      {/* <Route path="/tickets/:type" element={<TicketTable />} />
      <Route path="/summary/:type" element={<SummaryTable />} /> */}
      {/* <Route path="/prioritytickets" element={<PriorityTickets />} /> */}
      <Route path="/ApprovedTickets" element={<ApproveticketsPage />} />
      <Route path="/Opentickets" element={<OpenticketsPage />} />
      <Route path="/Ongoingtickets" element={<PendingticketsPage />} />
      <Route path="/Completedtickets" element={<CompletedticketsPage />} />
      <Route path="/Rejectedtickets" element={<ClosedticketsPage />} />
      <Route path="/ticketassign" element={<TicketAssignPage />} />
      <Route path="/tickets/:type" element={<TickettablePage />} />
      <Route path="/summary/:type" element={<SummaryCardtablePage />} />
      <Route path="/teamlist" element={<TeamListPage />} />
      <Route path="/addemployee" element={<AddemployeePage />} />
      <Route path="/userdetail" element={<ProfilePage />} />
      <Route path="/setchgback" element={<ChargeBackPage />} />
      <Route path="/chgbacklist" element={<ChargeBackListPage />} />
      <Route path="/chgbackallot" element={<AllotementListPage />} />
      <Route path="/countbyuser" element={<CountByUserListPage />} />

      {/* <Route path="/Profile" element={<Profile/>}/> */}
    </Routes>
  );
};
export default PrivateRoutes;
