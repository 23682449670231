import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { summaryCardsStats } from "../stores/dashboardStores/dashboardSlice";
import SummaryCardsTable from "../components/dashboard/SummaryCardsTable";

const SummaryCardtablePage = ({ ...props }) => {
  const dispatch = useDispatch();

  const summaryCardsData = useSelector(
    (state) => state.dashboardSlice.summaryCardStatsData
  );

  useEffect(() => {
    dispatch(summaryCardsStats());
  }, [dispatch]);

  // console.log(props, "this is table");
  // console.log(summaryCardsData, "this is data     kfbjkdfbdgbdwbfnen");

  return (
    <div>
      <SummaryCardsTable summaryCardsData={summaryCardsData} />
    </div>
  );
};

export default SummaryCardtablePage;
