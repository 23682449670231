import { LoadingButton } from "@mui/lab";
import {
  Container,
  Divider,
  FormControl,
  Grid2,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
const ChargeBackAllotementList = ({ list = [], loader }) => {
  const [selectedStatus, setSelectedStatus] = useState(""); // State for the selected status
  // Handle dropdown change
  const handleStatusChange = (event) => {
    const status = event.target.value;
    setSelectedStatus(status);
    console.log(selectedStatus);
  };
  return (
    <>
      <Container maxWidth="xl">
        <Typography
          sx={{ mb: 1, color: "grey", marginBottom: "2%", marginLeft: "2%" }}
        >
          ChargeBack {">"} Charge Back Allotement List
        </Typography>
        <Paper
          elevation={3}
          style={{
            borderRadius: "8px",
            border: "2px solid #027BFE",
            margin: "1%",
            padding: "2%",
          }}
        >
          <Typography variant="h5">ChargeBack Allotement List</Typography>
          <Divider />
          <TableContainer sx={{ marginTop: 1 }}>
            <Table>
              <TableHead sx={{ backgroundColor: "#1976d2" }}>
                <TableRow>
                  <TableCell sx={{ color: "white" }}>Sr No</TableCell>
                  <TableCell sx={{ color: "white" }}>Allotement Id</TableCell>
                  <TableCell sx={{ color: "white" }}>Charge Back Id</TableCell>
                  <TableCell sx={{ color: "white" }}>
                    Charge Back User
                  </TableCell>
                  <TableCell sx={{ color: "white" }}>
                    Charge Back Amount
                  </TableCell>
                  <TableCell sx={{ color: "white" }}>Parent</TableCell>
                  <TableCell sx={{ color: "white" }}>
                    Parent Freeze Amount
                  </TableCell>
                  <TableCell sx={{ color: "white" }}>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={8} style={{ textAlign: "center" }}>
                      No Transactions
                    </TableCell>
                  </TableRow>
                ) : (
                  list.map((txn, i) => (
                    <TableRow
                      key={i}
                      sx={{
                        backgroundColor: i % 2 === 0 ? "#EFF6FF" : "#BFDBFE",
                      }}
                    >
                      <TableCell>{i + 1}</TableCell>
                      <TableCell>{txn.id}</TableCell>
                      <TableCell>{txn.chargeback.chId}</TableCell>
                      <TableCell>{txn.chargeback.userId}</TableCell>
                      <TableCell>{txn.chargeback.chargeBackAmount}</TableCell>
                      <TableCell>{txn.userId}</TableCell>
                      <TableCell>{txn.freezeAmount}</TableCell>
                      <TableCell>{txn.status}</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Container>
    </>
  );
};

export default ChargeBackAllotementList;
