import React, { useEffect } from "react";
import ChargeBackList from "../../components/ChargeBackComp/ChargeBackList";
import { useDispatch, useSelector } from "react-redux";
import {
  changeStatus,
  getCHGList,
} from "../../stores/chargebackStores/chgSlice";

const ChargeBackListPage = () => {
  const dispatch = useDispatch();
  const listData = useSelector((state) => {
    // console.log("Redux state:", state); // Debug the entire state
    return state.chgSlice?.chgListData;
  });
  const listLoading = useSelector((state) => state.chgSlice?.chgListLoading);
  const changeStatusData = useSelector(
    (state) => state.chgSlice?.changeStatusData
  );
  const changeStatusLoading = useSelector(
    (state) => state.chgSlice?.chgListLoading
  );

  const loadData = (payload) => {
    dispatch(getCHGList(payload));
  };

  const onChangeStatus = (payload) => {
    dispatch(changeStatus(payload));
  };

  useEffect(() => {
    console.log(listData);
  }, [listLoading, changeStatusLoading]);
  return (
    <>
      <ChargeBackList
        loadList={loadData}
        dataList={listData}
        listloader={listLoading}
        changeStatus={onChangeStatus}
        statusChangeData={changeStatusData}
      />
    </>
  );
};

export default ChargeBackListPage;
