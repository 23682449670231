const localData = {};

// localData.setToken = (token) => localStorage.setItem("TOKEN", token);
// localData.getToken = () => localStorage.getItem("TOKEN");
// localData.removeToken = () => localStorage.removeItem("TOKEN");

localData.setIsLogin = (value) => localStorage.setItem("isLogin", value);
localData.getIsLogin = () => localStorage.getItem("isLogin");
localData.setID = (id) => localStorage.setItem("EMPLOYEE_ID", id);
localData.getID = () => localStorage.getItem("EMPLOYEE_ID");
localData.removeID = () => localStorage.removeItem("EMPLOYEE_ID");
localData.setEmpName = (value) => localStorage.setItem("empName", value);
localData.getEmpName = () => localStorage.getItem("empName");
localData.removeName = () => localStorage.removeItem("empName");

localData.setUsertype = (userType) =>
  localStorage.setItem("USERTYPE", userType);
localData.getUsertype = () => localStorage.getItem("USERTYPE");
localData.removeUsertype = () => localStorage.removeItem("USERTYPE");

export default localData;
