import { axios } from "../../helper/axios";
import { ASSIGN_TICKET, OPEN_TICKETS } from "./ticketmanagementConstants";

export const openticketsApi = async (payload) => {
  console.log(OPEN_TICKETS, "payload on hitting API");
  return axios.get(OPEN_TICKETS + `/${payload}`);
};

export const assignticketApi = async (payload) => {
  console.log(payload, "payload hitting on API");
  return axios.put(ASSIGN_TICKET, payload);
};

// export const putTicketApi = async (ticketId, payload) => {
//   console.log(UPDATE_TICKET, "payload on hitting PUT API");
//   return axios.put(`${UPDATE_TICKET}/${ticketId}`, payload);
// };

// export const openticketsApi = async (payload) => {       // => ////////// this is pagination api
//   console.log(OPEN_TICKETS, "payload on hitting API");
//   return axios.get(
//     OPEN_TICKETS +
//       `/${payload.status}?page=${payload.page}&size=${payload.size}`
//   );
// };
