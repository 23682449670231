import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  assignTicket,
  openTicket,
} from "../../stores/ticketmanagementStores/ticketmanagementSlice";
import PendingTickets from "../../components/ticketmanagement/PendingTickets";

const PendingticketsPage = () => {
  const dispatch = useDispatch();

  const pendingTicketData = useSelector(
    (state) => state.ticketmanagementSlice.openTicketData
  );

  const data = "ONGOING";

  const onHandleSubmit = async (val) => {
    console.log(val, "VAlue inside page");
    // const ticketData = [val];
    dispatch(assignTicket(val)).then(() => {
      dispatch(openTicket(data));
    });
  };

  useEffect(() => {
    dispatch(openTicket(data));
  }, [dispatch]);

  return (
    <div>
      <PendingTickets
        openTicketData={pendingTicketData}
        onHandleSubmit={onHandleSubmit}
      />
    </div>
  );
};

export default PendingticketsPage;
