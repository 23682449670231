import React, { useEffect, useState } from "react";
import ChargeBackAllotementList from "../../components/ChargeBackComp/ChargeBackAllotementList";
import { useDispatch, useSelector } from "react-redux";
import { getCHGAllocationList } from "../../stores/chargebackStores/chgSlice";

const AllotementListPage = () => {
  const dispatch = useDispatch();
  const listData = useSelector((state) => {
    // console.log("Redux state:", state); // Debug the entire state
    return state.chgSlice?.allocationListData;
  });
  const listLoading = useSelector(
    (state) => state.chgSlice.allocationListLoading
  );

  const [list, setList] = useState([]);
  // Dispatch the API call only once on mount
  useEffect(() => {
    dispatch(getCHGAllocationList());
  }, [dispatch]);

  // Update local state when listData changes, but avoid re-triggering the API call
  useEffect(() => {
    if (listData?.status === 200) {
      setList(listData?.users || []);
    }
  }, [listData]); // This will not trigger the API call

  return (
    <>
      <ChargeBackAllotementList loader={listLoading} list={list} />
    </>
  );
};

export default AllotementListPage;
