export const SEARCH_TRANSACTION = "/myapp/auth/findtxn";

export const FREEZE_AMOUNT = "/myapp/auth/freezeamount";

export const GETCHARGEBACK_LIST = "/myapp/auth/getchargebacklist";

export const GETCHARGEBACKALLCATION_LIST =
  "/myapp/auth/getchargebackallocationlistall";

export const CHANGECHGSTATUS = "/myapp/auth/changechargestatus";

export const CHGCOUNTBYUSER = "/myapp/auth/userscount";
