import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  assignTicket,
  openTicket,
} from "../../stores/ticketmanagementStores/ticketmanagementSlice";
import OpenTickets from "../../components/ticketmanagement/OpenTickets";

const OpenticketsPage = () => {
  const dispatch = useDispatch();

  const openTicketData = useSelector(
    (state) => state.ticketmanagementSlice.openTicketData
  );

  const data = "OPEN";

  const onHandleSubmit = async (val) => {
    console.log(val, "VAlue inside page");
    // const ticketData = [val];
    dispatch(assignTicket(val)).then(() => {
      dispatch(openTicket(data));
    });
  };

  useEffect(() => {
    dispatch(openTicket(data));
  }, [dispatch]);

  return (
    <div>
      <OpenTickets
        openTicketData={openTicketData}
        onHandleSubmit={onHandleSubmit}
      />
    </div>
  );
};

export default OpenticketsPage;

////////////////////////////For Multiple Tickets/////////////////////////////

// import React, { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { openTicket } from "../../stores/ticketmanagementStores/ticketmanagementSlice";
// import OpenTickets from "../../components/ticketmanagement/OpenTickets";

// const OpenticketsPage = () => {
//   const dispatch = useDispatch();

//   const openTicketData = useSelector(
//     (state) => state.ticketmanagementSlice.openTicketData
//   );

//   // Define an array of statuses
//   const statuses = ["OPEN", "ONGOING", "APPROVED","ASSIGNED"];

//   useEffect(() => {
//     // Dispatch the openTicket action for each status
//     statuses.forEach((status) => {
//       dispatch(openTicket(status));
//     });
//   }, [dispatch]);

//   return (
//     <div>
//       <OpenTickets openTicketData={openTicketData} />
//     </div>
//   );
// };

// export default OpenticketsPage;
