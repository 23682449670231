import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Box, Typography, TablePagination } from "@mui/material";

// Styling for alternating row colors and row height, matching TicketTable
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#EFF6FF", // Light row color
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#BFDBFE", // Dark row color
  },
  height: "50px", // Uniform row height to match TicketTable
}));

// Styling for table header cells, same as TicketTable
const TableHeaderCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#027BFE", // Blue header background
  color: "#FFFFFF", // White text for header
  fontWeight: "bold",
  fontSize: "14px", // Consistent font size with TicketTable
  padding: "12px", // Padding matching TicketTable
  textAlign: "center",
  height: "50px", // Match row height
}));

// Styling for table cells to match the TicketTable
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: "8px", // Consistent padding with TicketTable
  fontSize: "14px", // Match font size with TicketTable
  textAlign: "center",
  height: "50px", // Uniform row height
}));

export default function SummaryCardsTable({ summaryCardsData }) {
  const location = useLocation(); // Access passed state
  const { id, title } = location.state || {}; // Extract the id (ticket type)

  const [page, setPage] = React.useState(0); // State for current page
  const [rowsPerPage, setRowsPerPage] = React.useState(5); // Rows per page

  // Get the appropriate tickets array based on status (id)
  const getTicketsByStatus = () => {
    switch (id) {
      case 1:
        return summaryCardsData?.offlineBankFunds || [];
      case 2:
        return summaryCardsData?.infoIssue || [];
      case 3:
        return summaryCardsData?.paymentIssue || [];
      case 4:
        return summaryCardsData?.otherIssue || [];
      case 5:
        return summaryCardsData?.serviceIssue || [];
      default:
        return [];
    }
  };

  const filteredTickets = getTicketsByStatus();

  // Paginate the filtered tickets
  const paginatedTickets = filteredTickets.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <div>
      <Typography
        variant="h6"
        sx={{ mb: 1, color: "grey", marginBottom: "2%" }}
        style={{ fontSize: "20px", fontWeight: "bold" }} // Match heading size with TicketTable
      >
        Ticket Management > {title}
      </Typography>
      <Paper elevation={3} sx={{ borderRadius: "12px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: 16,
          }}
        >
          <Typography sx={{ fontWeight: "bold", fontSize: "20px" }}>
            {" "}
            {/* Match heading size */}
            {title} List
          </Typography>
        </div>
        <Box sx={{ p: 2 }}>
          <TableContainer component={Paper} sx={{ borderRadius: "12px" }}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="tickets table"
            >
              <TableHead>
                <TableRow>
                  <TableHeaderCell>ID</TableHeaderCell>
                  <TableHeaderCell>Remark</TableHeaderCell>
                  <TableHeaderCell>Issue Type</TableHeaderCell>
                  <TableHeaderCell>Subject</TableHeaderCell>
                  <TableHeaderCell>Payment Mode</TableHeaderCell>
                  <TableHeaderCell>Status</TableHeaderCell>
                  <TableHeaderCell>Date</TableHeaderCell>
                  <TableHeaderCell>User ID</TableHeaderCell>
                  {/* <TableHeaderCell>Parent</TableHeaderCell> */}
                  <TableHeaderCell>Amount</TableHeaderCell>
                  <TableHeaderCell>Service Name</TableHeaderCell>
                  {/* <TableHeaderCell>Parent Name</TableHeaderCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedTickets.length > 0 ? (
                  paginatedTickets.map((item) => (
                    <StyledTableRow key={item.id}>
                      <StyledTableCell>{item.id}</StyledTableCell>
                      <StyledTableCell>{item.remark}</StyledTableCell>
                      <StyledTableCell>{item.issueType}</StyledTableCell>
                      <StyledTableCell>{item.subject}</StyledTableCell>
                      <StyledTableCell>
                        {item.paymentMode || "N/A"}
                      </StyledTableCell>
                      <StyledTableCell>{item.status}</StyledTableCell>
                      <StyledTableCell>
                        {item.date
                          ? new Date(item.date).toLocaleString()
                          : "N/A"}
                      </StyledTableCell>
                      <StyledTableCell>{item.userid || "N/A"}</StyledTableCell>
                      {/* <StyledTableCell>{item.parent || "N/A"}</StyledTableCell> */}
                      <StyledTableCell>
                        {item.amount !== null ? item.amount : "N/A"}
                      </StyledTableCell>
                      <StyledTableCell>
                        {item.service_name || "N/A"}
                      </StyledTableCell>
                      {/* <StyledTableCell>{item.parentName || "N/A"}</StyledTableCell> */}
                    </StyledTableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={12} align="center">
                      No tickets available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Pagination */}
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredTickets.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(event, newPage) => setPage(newPage)}
            onRowsPerPageChange={(event) => {
              setRowsPerPage(parseInt(event.target.value, 10));
              setPage(0); // Reset to first page when rows per page changes
            }}
          />
        </Box>
      </Paper>
    </div>
  );
}
