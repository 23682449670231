import React from "react";
import { Formik } from "formik";
import * as Yup from "yup"; // Import Yup for validation
import CommonInput from "../common/commonInputBox/CommonInput";
import CommonSelect from "../common/CommonSelect";
import { colors } from "../../res/colors";
import CommonButton from "../common/commonInputButton/CommonButton";
import { Paper, Typography, Box, FormHelperText } from "@mui/material";

// Validation schema using Yup
const validationSchema = Yup.object({
  empNo: Yup.string().required("Employee number is required"),
  firstName: Yup.string().required("First name is required"),
  middleName: Yup.string().required("Middle name is required"),
  lastName: Yup.string().required("Last name is required"),
  mobileNumber: Yup.string()
    .matches(/^[0-9]{10}$/, "Mobile number must be exactly 10 digits")
    .required("Mobile number is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  utype: Yup.string().required("User type is required"),
});

const AddEmployee = ({ onHandleSubmit, departmentData }) => {
  console.log(departmentData);
  return (
    <div>
      <Typography
        variant="h6"
        sx={{ mb: 1, color: "grey", marginBottom: "2%", marginLeft: "2%" }}
      >
        Team Management > Add Employee
      </Typography>
      <Paper
        elevation={3}
        style={{
          borderRadius: "8px",
          border: "2px solid #027BFE",
          margin: "2%",
          padding: "2%",
        }}
      >
        <Typography
          sx={{ fontWeight: "bold", fontSize: "20px", marginBottom: "2%" }}
        >
          Add Employee
        </Typography>

        <Formik
          initialValues={{
            empNo: "",
            firstName: "",
            middleName: "",
            lastName: "",
            mobileNumber: "",
            email: "",
            utype: "",
          }}
          validationSchema={validationSchema} // Integrating validation schema
          onSubmit={async (values, { resetForm }) => {
            onHandleSubmit(values);
            resetForm();
          }}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue,
            values,
            touched,
            errors,
          }) => (
            <>
              <Box>
                {/* Emp No & First Name */}
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "2%",
                  }}
                >
                  <Box style={{ width: "50%" }}>
                    <Typography color="textPrimary">Employee Number</Typography>
                    <CommonInput
                      onChange={(text) => handleChange("empNo")(text)}
                      onBlur={handleBlur("empNo")}
                      value={values.empNo}
                      id="register-emp-no"
                      pleaceHolder="Enter Employee Number"
                      type="input"
                      style={{ width: "95%" }}
                    />
                    {touched.empNo && errors.empNo && (
                      <FormHelperText error>{errors.empNo}</FormHelperText>
                    )}
                  </Box>
                  <Box style={{ width: "50%" }}>
                    <Typography color="textPrimary">First Name</Typography>
                    <CommonInput
                      onChange={(text) => handleChange("firstName")(text)}
                      onBlur={handleBlur("firstName")}
                      value={values.firstName}
                      pleaceHolder="Enter First Name"
                      type="input"
                      style={{ width: "95%" }}
                    />
                    {touched.firstName && errors.firstName && (
                      <FormHelperText error>{errors.firstName}</FormHelperText>
                    )}
                  </Box>
                </Box>

                {/* Middle Name & Last Name */}
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "2%",
                  }}
                >
                  <Box style={{ width: "50%" }}>
                    <Typography color="textPrimary">Middle Name</Typography>
                    <CommonInput
                      onChange={(text) => handleChange("middleName")(text)}
                      onBlur={handleBlur("middleName")}
                      value={values.middleName}
                      pleaceHolder="Enter Middle Name"
                      type="input"
                      style={{ width: "95%" }}
                    />
                    {touched.middleName && errors.middleName && (
                      <FormHelperText error>{errors.middleName}</FormHelperText>
                    )}
                  </Box>
                  <Box style={{ width: "50%" }}>
                    <Typography color="textPrimary">Last Name</Typography>
                    <CommonInput
                      onChange={(text) => handleChange("lastName")(text)}
                      onBlur={handleBlur("lastName")}
                      value={values.lastName}
                      pleaceHolder="Enter Last Name"
                      type="input"
                      style={{ width: "95%" }}
                    />
                    {touched.lastName && errors.lastName && (
                      <FormHelperText error>{errors.lastName}</FormHelperText>
                    )}
                  </Box>
                </Box>

                {/* Mobile Number & Email */}
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "2%",
                  }}
                >
                  <Box style={{ width: "50%" }}>
                    <Typography color="textPrimary">Mobile</Typography>
                    <CommonInput
                      onChange={(text) => handleChange("mobileNumber")(text)}
                      onBlur={handleBlur("mobileNumber")}
                      value={values.mobileNumber}
                      pleaceHolder="Enter Mobile Number"
                      type="input"
                      style={{ width: "95%" }}
                    />
                    {touched.mobileNumber && errors.mobileNumber && (
                      <FormHelperText error>
                        {errors.mobileNumber}
                      </FormHelperText>
                    )}
                  </Box>
                  <Box style={{ width: "50%" }}>
                    <Typography color="textPrimary">Email</Typography>
                    <CommonInput
                      onChange={(text) => handleChange("email")(text)}
                      onBlur={handleBlur("email")}
                      value={values.email}
                      pleaceHolder="Enter Email"
                      type="input"
                      style={{ width: "95%" }}
                    />
                    {touched.email && errors.email && (
                      <FormHelperText error>{errors.email}</FormHelperText>
                    )}
                  </Box>
                </Box>

                {/* Department */}
                <Box style={{ marginBottom: "2%" }}>
                  <Typography color="textPrimary">Department</Typography>
                  <CommonSelect
                    onChange={(text) => {
                      console.log(text);
                      const value = parseInt(text.target.value, 10);
                      setFieldValue("utype", value);
                    }}
                    onBlur={handleBlur("utype")}
                    value={values.utype || ""}
                    name="department"
                    id="department"
                    data={departmentData}
                    style={{
                      width: "48%",
                      padding: "20px",
                    }}
                  />
                  {touched.utype && errors.utype && (
                    <FormHelperText error>{errors.utype}</FormHelperText>
                  )}
                </Box>

                {/* Buttons */}
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "20px",
                    marginRight: "32px",
                  }}
                >
                  <CommonButton
                    style={{
                      borderColor: colors.inputTextBorderColorRGBA,
                      color: colors.inputTextBorderColor,
                      fontWeight: "bold",
                      marginRight: "3%",
                      backgroundColor: colors.cancelBackgroundColor,
                      padding: "10px 12px",
                      fontSize: "14px",
                      minWidth: "80px",
                      height: "40px",
                    }}
                    value="Cancel"
                    onClick={handleReset}
                  />
                  <CommonButton
                    style={{
                      fontWeight: "bold",
                      padding: "10px 12px",
                      fontSize: "14px",
                      minWidth: "80px",
                      height: "40px",
                    }}
                    onClick={handleSubmit}
                    value="Submit"
                  />
                </Box>
              </Box>
            </>
          )}
        </Formik>
      </Paper>
    </div>
  );
};

export default AddEmployee;
