import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openTicket } from "../../stores/ticketmanagementStores/ticketmanagementSlice";
import CompletedTickets from "../../components/ticketmanagement/CompletedTickets";

const CompletedticketsPage = () => {
  const dispatch = useDispatch();

  const completedTicketData = useSelector(
    (state) => state.ticketmanagementSlice.openTicketData
  );

  const data = "COMPLETED";

  useEffect(() => {
    dispatch(openTicket(data));
  }, [dispatch]);

  return (
    <div>
      <CompletedTickets openTicketData={completedTicketData} />
    </div>
  );
};

export default CompletedticketsPage;
