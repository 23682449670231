import React from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const Notify = (
  message,
  type = "default",
  position = "top-right",
  customClass = ""
) => {
  const options = {
    position,
    className: customClass,
  };
  switch (type) {
    case "success":
      toast.success(message, options);
      break;
    case "error":
      toast.error(message, options);
      break;
    case "warn":
      toast.warn(message, options);
      break;
    case "info":
      toast.info(message, options);
      break;
    default:
      toast(message, options);
      break;
  }
};
export default function ToastifyContainer() {
  return <ToastContainer autoClose={2000} />;
}
