import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CountByUser from "../../components/ChargeBackComp/CountByUser";
import { getCountByUser } from "../../stores/chargebackStores/chgSlice";

const CountByUserListPage = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.chgSlice.getCountByUserLoading);
  const listData = useSelector((state) => state.chgSlice.getCountByUserData);
  useEffect(() => {
    dispatch(getCountByUser());
  }, [dispatch]);
  return (
    <>
      <CountByUser loader={loading} listData={listData} />
    </>
  );
};

export default CountByUserListPage;
