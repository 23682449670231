import { LoadingButton } from "@mui/lab";
import {
  Container,
  Divider,
  FormControl,
  Grid2,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

const statuses = ["PENDING", "COMPLETED", "REJECTED"]; // Define the statuses
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  ...theme.applyStyles("dark", {
    backgroundColor: "#1A2027",
  }),
}));
const ChargeBackList = ({
  loadList,
  dataList,
  listloader,
  changeStatus,
  statusChangeData,
}) => {
  const [selectedStatus, setSelectedStatus] = useState(""); // State for the selected status
  const [list, setList] = useState([]);
  // Handle dropdown change
  const handleStatusChange = (event) => {
    const status = event.target.value;
    setSelectedStatus(status);
  };

  const onGetList = () => {
    console.log(selectedStatus);
    if (selectedStatus) {
      const headerselected = { status: selectedStatus };
      const payload = { headers: headerselected };
      loadList(payload);
    } else {
      toast.error("Please Select Status First");
    }
  };

  const handleReject = (id) => {
    const headerselected = { chId: id, status: "REJECTED" };
    const payload = { headers: headerselected };
    changeStatus(payload);
  };
  const handleComplete = (id) => {
    const headerselected = { chId: id, status: "COMPLETED" };
    const payload = { headers: headerselected };
    changeStatus(payload);
  };
  // Update `list` whenever `dataList` changes
  useEffect(() => {
    if (dataList?.users) {
      setList(dataList.users); // Safely update list
    } else {
      setList([]); // Reset to empty if no data
    }
  }, [dataList]);

  return (
    <>
      <Container maxWidth="xl">
        <Typography
          sx={{ mb: 1, color: "grey", marginBottom: "2%", marginLeft: "2%" }}
        >
          ChargeBack {">"} Charge Back List
        </Typography>
        <Paper
          elevation={3}
          style={{
            borderRadius: "8px",
            border: "2px solid #027BFE",
            margin: "1%",
            padding: "2%",
          }}
        >
          <Typography variant="h5">ChargeBack List</Typography>
          <Divider />
          <Stack direction="column" sx={{ marginTop: 1 }} spacing={1}>
            <Typography>Please select status type first...</Typography>

            <Item style={{ width: "50%" }}>
              <FormControl fullWidth>
                <InputLabel id="status-label">Select Status</InputLabel>
                <Select
                  labelId="status-label"
                  label="Select Status"
                  value={selectedStatus}
                  onChange={handleStatusChange}
                >
                  {statuses.map((status) => (
                    <MenuItem key={status} value={status}>
                      {status}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Item>
            <LoadingButton
              sx={{ textAlign: "end", width: "50%" }}
              size="medium"
              onClick={onGetList}
              loading={listloader}
            >
              Get List..
            </LoadingButton>
            <Item>
              <TableContainer>
                <Table>
                  <TableHead sx={{ backgroundColor: "#1976d2" }}>
                    <TableRow>
                      <TableCell sx={{ color: "white" }}>Sr No</TableCell>
                      <TableCell sx={{ color: "white" }}>
                        Charge Back Id
                      </TableCell>
                      <TableCell sx={{ color: "white" }}>
                        Charge Back Created
                      </TableCell>
                      <TableCell sx={{ color: "white" }}>
                        Tranaction Id
                      </TableCell>
                      <TableCell sx={{ color: "white" }}>
                        Tranaction Type
                      </TableCell>
                      <TableCell sx={{ color: "white" }}>
                        Tranaction Date
                      </TableCell>
                      <TableCell sx={{ color: "white" }}>
                        Tranaction Amount
                      </TableCell>
                      <TableCell sx={{ color: "white" }}>
                        ChargeBack Amount
                      </TableCell>
                      <TableCell sx={{ color: "white" }}>
                        ChargeBack Status
                      </TableCell>
                      <TableCell sx={{ color: "white" }}>
                        ChargeBack User
                      </TableCell>
                      <TableCell sx={{ color: "white" }}>
                        ChargeBack Last Updated
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {list.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={10} style={{ textAlign: "center" }}>
                          No Transactions
                        </TableCell>
                      </TableRow>
                    ) : (
                      list.map((txn, i) => (
                        <TableRow
                          key={i}
                          sx={{
                            backgroundColor:
                              i % 2 === 0 ? "#EFF6FF" : "#BFDBFE",
                          }}
                        >
                          <TableCell>{i + 1}</TableCell>
                          <TableCell>{txn.chId}</TableCell>
                          <TableCell>
                            {txn.created
                              ? new Date(txn.created).toLocaleString()
                              : "N/A"}
                          </TableCell>
                          <TableCell>{txn.txnId}</TableCell>
                          <TableCell>{txn.txnType}</TableCell>
                          <TableCell>
                            {txn.txnDate
                              ? new Date(txn.txnDate).toLocaleString()
                              : "N/A"}
                          </TableCell>
                          <TableCell>{txn.amount}</TableCell>
                          <TableCell>{txn.chargeBackAmount}</TableCell>
                          <TableCell>
                            {txn.settlementStatus === "PENDING" ? (
                              <>
                                <Typography>
                                  Current Status: {txn.settlementStatus}
                                </Typography>
                                <Stack direction="row" spacing={1}>
                                  <LoadingButton
                                    variant="contained"
                                    color="error"
                                    onClick={() => handleReject(txn.chId)}
                                  >
                                    Reject
                                  </LoadingButton>
                                  <LoadingButton
                                    variant="contained"
                                    color="success"
                                    onClick={() => handleComplete(txn.chId)}
                                  >
                                    Complete
                                  </LoadingButton>
                                </Stack>
                              </>
                            ) : txn.settlementStatus === "REJECTED" ? (
                              <>
                                <Typography>
                                  Status: {txn.settlementStatus}
                                </Typography>
                                <LoadingButton
                                  variant="contained"
                                  color="success"
                                  onClick={() => handleComplete(txn.chId)}
                                >
                                  Complete
                                </LoadingButton>
                              </>
                            ) : (
                              <Typography>
                                Status: {txn.settlementStatus}
                              </Typography>
                            )}
                          </TableCell>

                          <TableCell>{txn.userId}</TableCell>
                          <TableCell>{txn.updated}</TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Item>
          </Stack>
        </Paper>
      </Container>
    </>
  );
};

export default ChargeBackList;
