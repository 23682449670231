import { Box, Button, Grid2, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  AddCircleOutline as AddCircleOutlineIcon,
  Assignment as AssignmentIcon,
  ErrorOutline as ErrorOutlineIcon,
} from "@mui/icons-material";
import CardItem from "../common/CardItem";
import SummaryCard from "../common/SummaryCard";
// import PieChart from "./PieChart";
// import NewTicket from '../TicketSection/NewTicket';
import { useNavigate } from "react-router-dom";
import PieChart from "./PieChart";
import localData from "../../helper/localData";

const Dashboard = ({ summaryStatsData, ticketStatsData, tableStatsData }) => {
  const navigate = useNavigate();
  const [ticketStats, setTicketStats] = useState([
    { id: 1, title: "Waiting-Tickets", value: 0, color: "#000080" },
    { id: 2, title: "Completed-Tickets", value: 0, color: "#008000" },
    { id: 3, title: "Pending-Tickets", value: 0, color: "#FFA500" },
    { id: 4, title: "Escalated-Tickets", value: 0, color: "#FF0000" },
  ]);

  const [summaryStats, setsummaryStats] = useState([
    { id: 1, title: "Offline Bank Funds", value: 0, color: "#f57c00" },
    { id: 2, title: "Info Issue", value: 0, color: "#0288d1" },
    { id: 3, title: "Payment Issue", value: 0, color: "#d32f2f" },
    { id: 4, title: "Other Issue", value: 0, color: "#7b1fa2" },
    { id: 5, title: "Service Issue", value: 0, color: "#4CAF50" },
  ]);

  const [empName, setEmpName] = useState();
  const [lastTicketCount, setLastTicketCount] = useState();
  const [ticketdata, setTicketData] = useState([]);

  useEffect(() => {
    setEmpName(localData.getEmpName());
    if (summaryStatsData) {
      // Update summaryStats based on values from summaryStatsData
      const updatedSummaryStats = summaryStats.map((stat) => ({
        ...stat,
        value: summaryStatsData[stat.title] || 0, // Match value using title as key
      }));

      // Update the state with the new data
      setsummaryStats(updatedSummaryStats);
    }
    var toSetTotalTCount = 0;
    if (ticketStatsData) {
      const updateTicketStats = ticketStats.map((stat) => ({
        ...stat,
        value: ticketStatsData[stat.title] || 0,
      }));

      setTicketStats(updateTicketStats);
      setTicketData(updateTicketStats);
    }
    ticketdata.map((stat) => {
      console.log(stat);
      toSetTotalTCount = toSetTotalTCount + stat.value;
      console.log(toSetTotalTCount);
    });
    setLastTicketCount(toSetTotalTCount);
  }, [summaryStatsData, ticketStatsData]);

  // useEffect(() => {

  // }, [setTicketStats]);

  const handleCardClick = (title, id) => {
    navigate(`/tickets/${title}`, { state: { id: id, title: title } });
  };

  const handleSummaryCardClick = (title, id) => {
    navigate(`/summary/${title}`, { state: { id: id, title: title } });
  };

  const handleCreateNewTicket = () => {
    console.log("Create new ticket");
    navigate("/newticket");
  };

  const handleReassignedTickets = () => {
    console.log("Tickets reassigned");
    navigate("/ticketreassign");
  };

  const handleHighPriorityTicket = () => {
    console.log("High priority ticket");
    navigate("/priorityticket");
  };

  return (
    <Box sx={{ padding: 4, backgroundColor: "#F5F7FA" }}>
      <Typography variant="h4" gutterBottom>
        Welcome back, {empName}
      </Typography>

      <Grid2 container spacing={3}>
        <Grid2
          size={{ xs: 12, md: 8 }}
          sx={{ padding: 2, backgroundColor: "white", borderRadius: 2 }}
        >
          {/* Ticket Stats */}
          <Typography variant="subtitle1" gutterBottom>
            You have total {lastTicketCount} tickets in Last 24-Hours
          </Typography>
          <Stack
            direction={"row"}
            spacing={2}
            useFlexGap
            sx={{ flexWrap: "wrap" }}
          >
            {ticketStats.map((stat, index) => (
              <Grid2 size={{ xs: 12, sm: 6 }} key={index} component="div">
                <CardItem
                  title={stat.title}
                  value={stat.value}
                  color={stat.color}
                  onClick={() => handleCardClick(stat.title, stat.id)}
                />
              </Grid2>
            ))}
          </Stack>
        </Grid2>

        <Grid2 size={{ xs: 12, md: 4 }}>
          {/* Buttons */}
          <Box sx={{ padding: 2, backgroundColor: "white", borderRadius: 2 }}>
            <Stack spacing={2}>
              <Button
                variant="outlined"
                startIcon={<AddCircleOutlineIcon />}
                fullWidth
                // color="#007BFF"
                sx={{ borderColor: "#007BFF", color: "#007BFF" }}
                onClick={handleCreateNewTicket}
              >
                Create New Ticket
              </Button>

              <Button
                variant="outlined"
                startIcon={<AssignmentIcon />}
                fullWidth
                sx={{ borderColor: "#E533C4", color: "#E533C4" }}
                onClick={handleReassignedTickets}
              >
                Tickets Reassigned
              </Button>
              <Button
                variant="outlined"
                startIcon={<ErrorOutlineIcon />}
                fullWidth
                color="error"
                sx={{ borderColor: "#FF0000", color: "#FF0000" }}
                onClick={handleHighPriorityTicket}
              >
                High-Priority Ticket
              </Button>
            </Stack>
          </Box>
        </Grid2>
      </Grid2>

      <div style={{ marginTop: 16 }}></div>

      <Grid2 container spacing={3}>
        {/* Pie Chart */}
        <Grid2 size={{ xs: 12, md: 8 }} component="div">
          {/*new pie chart */}
          <PieChart tableStatsData={tableStatsData} />
        </Grid2>

        {/* Overall Summary */}
        <Grid2 size={{ xs: 12, md: 4 }} component="div">
          <CardItem>
            <h3>Overall Summary</h3>
            <Stack
              direction={"row"}
              spacing={2}
              useFlexGap
              sx={{ flexWrap: "wrap" }}
            >
              {summaryStats.map((stat, index) => (
                <Grid2 size={{ xs: 12, sm: 6 }} key={index} component="div">
                  <SummaryCard
                    title={stat.title}
                    value={stat.value}
                    color={stat.color}
                    onClick={() => handleSummaryCardClick(stat.title, stat.id)}
                  />
                </Grid2>
              ))}
            </Stack>
          </CardItem>
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default Dashboard;
